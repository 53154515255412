<script setup lang="ts">
import { useI18n } from "vue-i18n";

type BreakFlowComponentProps = {};

defineProps<BreakFlowComponentProps>();

const { t } = useI18n();
</script>
<template>
  <div class="breakpage">
    <p class="breakpage-info">
      &nbsp;{{ t("app.commonComponents.breakpage") }}&nbsp;
    </p>
  </div>
</template>
<style scoped lang="scss">
.breakpage {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    border-top: 2px dotted black;
    width: 100%;
  }

  .breakpage-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    text-align: center;
    word-wrap: break-word;
    font-size: small;
    background-color: white;
  }
}
</style>
