<script setup lang="ts">
import type { PageItemTypes, PageItemType } from "../Page/Page";

type SignColumnProps = {
  itemId: string;
  pageItemType: PageItemTypes;
  item: PageItemType;
  textDirection: "vertical" | "horizontal" | undefined;
};

const props = defineProps<SignColumnProps>();

// function columnStyle() {
//   if ((props.item.details as SignDetails)?.fsw) {
//     switch ((props.item.details as SignDetails).fsw[0]) {
//       case "L":
//         return "grid-column: 1;";
//       case "M":
//         return "grid-column: 2;";
//       case "R":
//         return "grid-column: 3;";
//       default: // Middle
//         return "grid-column: 2;";
//     }
//   }
// }
</script>
<template>
  <div class="sign-column-container" :text-direction="props.textDirection">
    <div class="sign-column-item">
      <slot></slot>
    </div>
  </div>
</template>
<style scoped lang="scss">
.sign-column-container {
  position: relative;
  // padding: 0 0.5rem 0 0.5rem;
  width: min-content;

  &[right="true"] {
    border-right: 0.5px black solid;
  }
  &[left="true"] {
    border-left: 0.5px black solid;
  }

  &[middle="true"] {
    &::before {
      content: "";
      position: absolute;
      top: 0%;
      left: 50%;
      height: 100%;
      width: 0.5px;
      border-right: 0.5px dashed black;
    }
  }

  &[text-direction="vertical"] {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    min-width: 3.5rem;
  }
}
</style>
@/components/common/Page/Page
