import { info } from "@sutton-signwriting/core/fsw";

export enum ColumnItemType {
  SIGN = "sign",
  SIGN_PUNCTUATION = "signPunctuation",
  BREAK_FLOW = "breakflow",
  BREAK_PAGE = "breakpage",
  SPACE = "space",
}

export default class ColumnItem {
  public id: string;
  public type: ColumnItemType;
  public width: number = 0;
  public height: number = 0;
  public parameters: { [key: string]: any };
  private _content: { [key: string]: any };
  private _padding: number = 30; // It must not be used in CSS, only in calculations. However, for this to be really appreciated, the element holding the item should centralize it.

  constructor(type: ColumnItemType, content: {}) {
    this.id = this._generateRandomId();
    this.type = type;
    this._content = content;
    this.parameters = this._getParametersFromType();
    this.width = this._getWidth();
    this.height = this._getHeight();
  }

  private _generateRandomId(): string {
    return crypto.randomUUID().slice(0, 5);
  }

  private _standardizeFswStyle(fsw: string): string {
    let enrichedFsw = fsw;

    // Applies zoom to the sign if it is not already applied
    if (!fsw.includes("-Z")) {
      enrichedFsw = fsw + "-Z0.7";
    }
    return enrichedFsw;
  }

  private _getKeyFromContent(key: string): any | null {
    return this._content[key] || null;
  }

  private _getParametersFromType() {
    const parameters: { [key: string]: any } = {};

    if (this.type === ColumnItemType.SIGN_PUNCTUATION) {
      const fsw = this._getKeyFromContent("fsw");

      if (fsw) {
        const normalizedFsw = this._standardizeFswStyle(fsw);
        const signInfo = info(normalizedFsw);
        parameters.fsw = normalizedFsw;
        parameters.width = signInfo.width;
        parameters.height = signInfo.height + 2;
      }
    }

    if (this.type === ColumnItemType.SIGN) {
      const fsw = this._getKeyFromContent("fsw");

      if (fsw) {
        const normalizedFsw = this._standardizeFswStyle(fsw);
        parameters.fsw = normalizedFsw;
      }
    }

    return parameters;
  }

  public _getWidth(): number {
    let width = 0;

    if (this.type === ColumnItemType.BREAK_FLOW) {
      width = 30;
    }

    if (
      this.type === ColumnItemType.SIGN ||
      this.type === ColumnItemType.SIGN_PUNCTUATION
    ) {
      const fsw = this._getKeyFromContent("fsw");
      const normalizedFsw = this._standardizeFswStyle(fsw);

      if (fsw) {
        const signInfo = info(normalizedFsw);
        width = signInfo.width;
      }
    }

    const widthFoundInContent = this._getKeyFromContent("width");
    if (widthFoundInContent) {
      width = widthFoundInContent;
    }
    return width;
  }

  public _getHeight(): number {
    let height = 0;

    if (this.type === ColumnItemType.BREAK_FLOW) {
      height = 30;
    }

    if (this.type === ColumnItemType.SIGN) {
      const fsw = this._getKeyFromContent("fsw");
      const normalizedFsw = this._standardizeFswStyle(fsw);

      if (fsw) {
        const signInfo = info(normalizedFsw);
        height =
          signInfo.height - (signInfo.height - signInfo.height * signInfo.zoom);
      }
    }

    if (this.type === ColumnItemType.SIGN_PUNCTUATION) {
      const fsw = this._getKeyFromContent("fsw");
      const normalizedFsw = this._standardizeFswStyle(fsw);

      if (fsw) {
        const signInfo = info(normalizedFsw);
        height = signInfo.height;
      }
    }

    const heightFoundInContent = this._getKeyFromContent("height");
    if (heightFoundInContent) {
      height = heightFoundInContent;
    }
    return height + this._padding;
  }
}
