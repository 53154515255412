import { ref } from "vue";
import { Page } from "../components/common/Page/Page";
import type {
  PuddleInfo,
  SignPuddleMatch,
  SignPuddleSearchEndPointResult,
} from "./SignPuddle3Client";

export type DocumentInfo = {
  id: string;
  title: string;
  createdAt: string;
  lastModified: string;
};

export type UserPreferences = {
  country: {
    code: string;
    name: string;
    puddle: PuddleInfo;
  };
  wordMatch: SignPuddleMatch;
};

const documents = ref<DocumentInfo[]>([]);
// const documents = ref<DocumentInfo[]>([
//   {
//     id: "1",
//     title: "Document 1",
//   },
//   {
//     id: "2",
//     title: "Document 2",
//   },
//   {
//     id: "3",
//     title: "Document 3",
//   },
// ]);

const pages = ref<Page[]>([]);
// const pages = ref<Page[]>([
//   new Page({
//     documentId: "1",
//     id: 1,
//     size: { width: 790, height: 1100 },
//   }),
//   new Page({
//     documentId: "1",
//     id: 2,
//     size: { width: 790, height: 1100 },
//   }),
//   new Page({
//     documentId: "1",
//     id: 3,
//     size: { width: 1100, height: 790 },
//   }),
//   new Page({
//     documentId: "2",
//     id: 1,
//     size: { width: 790, height: 1100 },
//   }),
//   new Page({
//     documentId: "2",
//     id: 2,
//     size: { width: 1100, height: 790 },
//   }),
//   new Page({
//     documentId: "2",
//     id: 3,
//     size: { width: 790, height: 1100 },
//   }),
// ]);

export class LocalHostClient {
  private _documentsKey = "document_";
  private _pagesKey = "page_";
  private _preSelectedSignKey = "preSelectedSign_";
  private _userPreferencesKey = "userPreferences";
  private _countryPuddlesKey = "countryPuddles_";

  constructor() {
    documents.value.forEach((document) => {
      const key = this._documentsKey + document.id;
      if (!localStorage.getItem(key)) {
        localStorage.setItem(key, JSON.stringify(document));
      }
    });

    pages.value.forEach((page) => {
      const key = this._pagesKey + page.id;
      if (!localStorage.getItem(key)) {
        localStorage.setItem(key, JSON.stringify(page));
      }
    });
  }

  public createDocument(info?: Partial<DocumentInfo>): DocumentInfo {
    const documents = this.getDocuments();
    const newDocument: DocumentInfo = {
      id: info?.id ?? (documents.length + 1).toString(),
      title: info?.title ?? "",
      lastModified: info?.lastModified ?? "",
      createdAt: info?.lastModified ?? new Date().toString(),
    };
    localStorage.setItem(
      this._documentsKey + newDocument.id,
      JSON.stringify(newDocument),
    );
    this.createPage(newDocument.id);

    return newDocument;
  }

  public getDocumentById(documentId: string): DocumentInfo {
    const key = this._documentsKey + documentId;
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  public deleteDocumentById(documentId: string): void {
    const key = this._documentsKey + documentId;
    localStorage.removeItem(key);
    this.deletePagesByDocumentId(documentId);
  }

  /**
   * This function returns all documents from the local storage that have a key that follows the 'document_{n digits}' pattern.
   *
   * Example:
   *  Returns true if: document_1
   *  Returns false if: document_1_page_1
   *
   * @returns Array of documents
   */
  public getDocuments(): DocumentInfo[] {
    const keys = Object.keys(localStorage).filter((key) =>
      /^document_\d+$/.test(key),
    );

    return keys.map((key) => {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    });
  }

  /**
   * This function returns all pages from the local storage that have a key that follows the 'document_{n digits}_page_{n digits}' pattern.
   *
   * @param documentId
   * @returns Array of pages
   */
  public getPagesByDocumentId(documentId: string): Page[] {
    const keys = Object.keys(localStorage).filter((key) =>
      key.startsWith(`${this._documentsKey}${documentId}_${this._pagesKey}`),
    );
    const pages = keys.map(
      (key) => new Page(JSON.parse(localStorage.getItem(key) || "")),
    );
    return pages;
  }

  /**
   * This function edits the metadata of a document in the local storage.
   * It does not replace the entire document, only the properties that are passed in the metadata object.
   *
   * @param documentId
   * @param metadata
   * @returns the edited document
   */
  public editDocumentMetadata(
    documentId: string,
    metadata: Partial<DocumentInfo>,
  ): void {
    const key = this._documentsKey + documentId;
    const item = localStorage.getItem(key);

    if (!item) {
      return;
    }

    const document = JSON.parse(localStorage.getItem(key) || "");
    const newDocument = {
      ...document,
      ...metadata,
      lastModified: new Date().toString(),
    };
    localStorage.setItem(key, JSON.stringify(newDocument));

    console.debug(
      "LocalHostClient: editDocumentMetadata: Document metadata edited: ",
      newDocument,
    );

    return newDocument;
  }

  /**
   * This function creates a new page in the local storage.
   * It assigns the page id as the number of pages in the document + 1.
   *
   * @param documentId
   * @returns the new page
   */
  public createPage(documentId: string): Page {
    const pages = this.getPagesByDocumentId(documentId);
    const newPage = new Page({
      documentId: documentId,
      id: pages.length + 1,
      size: { width: 794, height: 1135 },
      order: pages.length + 1,
    });
    const key = `${this._documentsKey}${documentId}_${this._pagesKey}${newPage.id}`;
    localStorage.setItem(
      key,
      JSON.stringify(newPage, (key, value) => {
        if (key.startsWith("_")) {
          return undefined; // exclude properties that start with '_'
        }
        return value;
      }),
    );

    return newPage;
  }

  public setOrReplacePages(newPages: Page[]): void {
    const documentId = newPages[0]?.documentId;

    if (!documentId) return; // Exit if no documentId is found

    // Step 1: Retrieve all keys for the document
    const documentKeysPrefix = `${this._documentsKey}${documentId}_`;
    const allKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith(documentKeysPrefix),
    );

    // Step 2: Create a set of new page IDs
    const newPageIds = new Set(
      newPages.map((page) => `${this._pagesKey}${page.id}`),
    );

    // Step 3: Remove pages not present in newPages
    allKeys.forEach((key) => {
      const pageId = key.split(documentKeysPrefix)[1];
      if (!newPageIds.has(pageId)) {
        localStorage.removeItem(key);
      }
    });

    // Step 4: Update or add new pages
    newPages.forEach((page) => {
      const key = `${documentKeysPrefix}${this._pagesKey}${page.id}`;
      localStorage.setItem(
        key,
        JSON.stringify(page, (key, value) => {
          if (key.startsWith("_")) {
            return undefined; // exclude properties that start with '_'
          }
          return value;
        }),
      );
    });

    // Step 5: Update document metadata
    this.editDocumentMetadata(documentId, {
      lastModified: new Date().toString(),
    });
  }

  public replacePage(page: Page): void {
    const key = `${this._documentsKey}${page.documentId}_${this._pagesKey}${page.id}`;
    localStorage.setItem(
      key,
      JSON.stringify(page, (key, value) => {
        if (key.startsWith("_")) {
          return undefined; // exclude properties that start with '_'
        }
        return value;
      }),
    );

    this.editDocumentMetadata(page.documentId, {
      lastModified: new Date().toString(),
    });
  }

  public deletePagesByDocumentId(documentId: string): void {
    const keys = Object.keys(localStorage).filter((key) =>
      key.startsWith(`${this._documentsKey}${documentId}_${this._pagesKey}`),
    );
    keys.forEach((key) => localStorage.removeItem(key));

    this.editDocumentMetadata(documentId, {
      lastModified: new Date().toString(),
    });
  }

  /**
   * This function saves the SignPuddleSearchEndPointResult item in the local storage, under the key 'preSelectedSign_{term}'.
   *
   * @param term
   * @param signItem
   */
  public saveSignPuddleSearchEndPointResultItem(
    term: string,
    signItem: SignPuddleSearchEndPointResult,
  ) {
    const key = this._preSelectedSignKey + term;
    localStorage.setItem(key, JSON.stringify(signItem));
  }

  /**
   * This function retrieves the SignPuddleSearchEndPointResult item from the local storage, under the key 'preSelectedSign_{term}'.
   *
   * @param term
   * @returns
   */
  public getSignPuddleSearchEndPointResultItem(
    term: string,
  ): SignPuddleSearchEndPointResult | null {
    const key = this._preSelectedSignKey + term;
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  public getUserPreferences(): UserPreferences | null {
    const preferences = localStorage.getItem(this._userPreferencesKey);
    return preferences ? JSON.parse(preferences) : null;
  }

  public setUserPreferences(preferences: UserPreferences) {
    localStorage.setItem(this._userPreferencesKey, JSON.stringify(preferences));
  }

  public setCountryPuddles(country: string, puddles: PuddleInfo[]) {
    localStorage.setItem(
      this._countryPuddlesKey + country,
      JSON.stringify(puddles),
    );
  }

  public getCountryPuddles(country: string): PuddleInfo[] {
    const puddles = localStorage.getItem(this._countryPuddlesKey + country);
    return puddles ? JSON.parse(puddles) : null;
  }
}
