<script setup lang="ts">
import { onMounted, ref } from "vue";
import { LocalHostClient, type DocumentInfo } from "@/api/localHostClient";
import BaseNavigationBar from "@/components/common/BaseNavigationBar/BaseNavigationBar.vue";
import CatalogItem from "./CatalogItem/CatalogItem.vue";
import { useI18n } from "vue-i18n";
import router from "@/router";
import appStore from "@/stores/AppStore";

const { t } = useI18n();

interface CatalogModel {
  client: LocalHostClient;
}

const { model } = defineProps<{ model: CatalogModel }>();
const documents = ref<DocumentInfo[]>();

onMounted(() => {
  documents.value = model.client.getDocuments();
  documents.value = documents.value?.sort((a, b) => {
    return Number(new Date(b.lastModified)) - Number(new Date(a.lastModified));
  });
});

function createDocument() {
  const newDocument = model.client.createDocument();
  documents.value = model.client.getDocuments();
  router.push({ name: "editor", query: { id: newDocument.id } });
}

function openDeleteDialog(document: DocumentInfo) {
  dialogSelectedDocument.value = document;
  showDialog.value = true;
  dialogDelete.value = true;
}

function deleteDocumentById(id: string) {
  model.client.deleteDocumentById(id);
  documents.value = model.client.getDocuments();
}

function deleteDocument() {
  if (undefined === dialogSelectedDocument.value) {
    throw new Error(
      "CatalogView: deleteDocument: dialogSelectedDocument is undefined",
    );
  }

  deleteDocumentById(dialogSelectedDocument.value.id);
  closeDialog();
}

const dialogSelectedDocument = ref<DocumentInfo>();
const titleToBeEdited = ref("");

function openRenameDialog(document: DocumentInfo) {
  dialogSelectedDocument.value = document;
  titleToBeEdited.value =
    document.title.length > 0 ? document.title : t("document.defaultTitle");
  showDialog.value = true;
  dialogRename.value = true;
}

function closeDialog() {
  showDialog.value = false;
  dialogRename.value = false;
  dialogDelete.value = false;
}

function rename() {
  if (undefined === dialogSelectedDocument.value) {
    throw new Error("CatalogView: rename: dialogSelectedDocument is undefined");
  }

  model.client.editDocumentMetadata(dialogSelectedDocument.value.id, {
    title: titleToBeEdited.value,
  });

  closeDialog();

  documents.value = model.client.getDocuments();

  documents.value = documents.value?.sort((a, b) => {
    return Number(new Date(b.lastModified)) - Number(new Date(a.lastModified));
  });
}

const showDialog = ref(false);
const dialogRename = ref(false);
const dialogDelete = ref(false);
</script>
<template>
  <main id="catalog__container" :theme="appStore.state.theme.selected.value">
    <div class="no-printable">
      <BaseNavigationBar></BaseNavigationBar>
    </div>
    <div class="catalog__warns">
      <v-alert
        :title="t('alerts.catalogView.offlineOnly.title')"
        :text="t('alerts.catalogView.offlineOnly.text')"
        color="#424242"
      />
    </div>
    <div id="catalog__content">
      <div id="recent__container">
        <div id="recent__content">
          <div id="recent__metadata">
            <p id="recent__title">
              {{ t("catalogView.recentDocuments.title") }}
            </p>
          </div>
          <div id="recent__documents">
            <CatalogItem
              v-for="(document, index) in documents"
              :key="index"
              :document-info="document"
              :delete-document="openDeleteDialog"
              :rename-document="openRenameDialog"
            />
          </div>
          <div
            v-if="!documents || documents.length === 0"
            id="no-documents__container"
          >
            <p id="no-documents__title">
              {{ t("catalogView.recentDocuments.empty.title") }}
            </p>
            <p id="no-documents__text">
              {{ t("catalogView.recentDocuments.empty.text") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <v-btn
      class="create-catalog-item-btn-container"
      icon="plus"
      size="large"
      elevation="5"
      @click="createDocument"
    />
    <v-dialog v-model="showDialog">
      <div id="document-rename__container" v-if="dialogRename">
        <div id="document-rename__dialog">
          <div>
            <p id="document-rename__title">
              {{ t("catalogView.renameDocumentDialog.title") }}
            </p>
            <p id="document-rename__text">
              {{ t("catalogView.renameDocumentDialog.text") }}
            </p>
            <input
              type="text"
              v-model="titleToBeEdited"
              @keydown.enter="rename"
            />
          </div>
          <div id="document-rename__actions">
            <v-btn
              variant="outlined"
              width="5rem"
              @click="closeDialog"
              style="
                text-transform: capitalize;
                border: 1px solid rgba(95, 99, 104, 0.5);
              "
            >
              <template #default>
                {{ t("catalogView.renameDocumentDialog.cancelBtn") }}
              </template>
            </v-btn>
            <v-btn
              base-color="blue"
              width="5rem"
              @click="rename"
              style="text-transform: capitalize"
            >
              <template #default>
                {{ t("catalogView.renameDocumentDialog.confirmBtn") }}
              </template>
            </v-btn>
          </div>
        </div>
      </div>
      <div id="document-rename__container" v-if="dialogDelete">
        <div id="document-rename__dialog">
          <div>
            <p id="document-rename__title">
              {{ t("catalogView.deleteDocumentDialog.title") }}
            </p>
            <p id="document-rename__text">
              {{ t("catalogView.deleteDocumentDialog.text") }}
            </p>
          </div>
          <div id="document-rename__actions">
            <v-btn
              variant="outlined"
              width="5rem"
              @click="closeDialog"
              style="
                text-transform: capitalize;
                border: 1px solid rgba(95, 99, 104, 0.5);
              "
            >
              <template #default>
                {{ t("catalogView.deleteDocumentDialog.cancelBtn") }}
              </template>
            </v-btn>
            <v-btn
              base-color="blue"
              width="5rem"
              @click="deleteDocument"
              style="text-transform: capitalize"
              color="red"
            >
              <template #default>
                {{ t("catalogView.deleteDocumentDialog.confirmBtn") }}
              </template>
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </main>
</template>
<style scoped lang="scss">
#catalog__container {
  position: relative;
  width: 100%;
  height: 100%;

  #catalog__content {
    #recent__container {
      width: 100%;
      display: flex;
      justify-content: center;

      #recent__content {
        padding-top: 1rem;
        width: 60%;

        #recent__metadata {
          margin-bottom: 1.5rem;

          #recent__title {
            font-weight: 500;
            display: inline-block;
          }
        }

        #recent__documents {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          margin-bottom: 2rem;
        }

        #no-documents__container {
          width: 100%;
          height: 8rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-shadow:
            1px -0.3px 1px rgba(0, 0, 0, 0.08),
            -0.4px 1px 3px rgba(0, 0, 0, 0.12);
          border-radius: 2px;

          #no-documents__title {
            font-size: 1.125rem;
            margin-bottom: 0.5rem;
          }

          #no-documents__text {
            color: rgb(95, 99, 104);
            font-size: 300;
          }
        }
      }
    }
  }

  .create-catalog-item-btn-container {
    position: fixed;
    bottom: 1.75rem;
    right: 2.5rem;
  }

  &[theme="dark"] {
    background-color: rgb(23, 23, 23, 0.9);

    #catalog__content {
      #recent__container {
        #recent__content {
          #recent__metadata {
            #recent__title {
              color: rgb(255, 255, 255);
            }
          }

          #recent__documents {
            .catalog__item {
              background-color: #bcbcbc;
              border: 1px solid rgba(255, 255, 255, 0.1);
            }
          }

          #no-documents__container {
            background-color: #bcbcbc;
            color: #bcbcbc;
            box-shadow:
              1px -0.3px 1px #bcbcbc,
              -0.4px 1px 3px #bcbcbc;

            #no-documents__title {
              color: rgb(0, 0, 0);
            }
          }
        }
      }
    }
  }
}

#document-rename__container {
  display: flex;
  justify-content: center;

  #document-rename__dialog {
    width: 21.438rem;
    height: max-content;
    padding: 1.5rem;
    background-color: white;
    border-radius: 4px;

    #document-rename__title {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    #document-rename__text {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: rgba(95, 99, 104, 0.8);
    }

    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(95, 99, 104, 0.5);
      padding: 0.2rem 0.3rem;
    }

    #document-rename__actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 600px) {
  #catalog__container {
    #catalog__content {
      #recent__container {
        #recent__content {
          width: 80%;
          #no-documents__container {
            min-width: 1rem;
            padding: 1rem;

            #no-documents__title {
              font-size: 1rem;
              font-weight: 500;
            }

            #no-documents__text {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}
</style>
