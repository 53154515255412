import mitt, { type Emitter } from "mitt";
import { onUnmounted } from "vue";
import { type alert } from "@/components/common/AlertComponent/AlertComponent.vue";
import type { SignPuddleSearchEndPointResult } from "@/api/SignPuddle3Client";

type EventHandlers<T extends Record<string, unknown>> = {
  [K in keyof T]: (event: T[K]) => void;
};

function useMittEvents<T extends Record<string, unknown>>(
  mitt: Emitter<T>,
  handlers: EventHandlers<T>,
) {
  for (const key of Object.keys(handlers)) {
    mitt.on(key, handlers[key]);
  }
  function cleanup() {
    for (const key of Object.keys(handlers)) {
      mitt.off(key, handlers[key]);
    }
  }
  onUnmounted(cleanup);
  return cleanup;
}

function wrapEventBus<T extends Record<string, unknown>>(mitt: Emitter<T>) {
  return (handlers: EventHandlers<T>) => useMittEvents(mitt, handlers);
}

// Event type
export type AppAlertEvent = {
  [addNotificationToAppAlertEvent: string]: alert;
};

// Event emitter
export const addNotificationToAppAlertEvent = mitt<AppAlertEvent>();

// Event listener
export const addNotificationToAppAlertEventBus = wrapEventBus(
  addNotificationToAppAlertEvent,
);

// Event type
export type AddSignToPage = {
  [addSignToPage: string]: {
    pageId: number;
    sign: SignPuddleSearchEndPointResult;
  };
};

// Event emitter
export const addSigntoPage = mitt<AddSignToPage>();

// Event listener
export const pageComponentAddSign = wrapEventBus(addSigntoPage);

// Event type
export type EditFswOfPageSign = {
  [editFswOfPageSign: string]: {
    pageId: number;
    signId: string;
    fsw: string;
  };
};

// Event emitter
export const editFSWOfPageSign = mitt<EditFswOfPageSign>();

// Event listener
export const pageComponentEditSignFsw = wrapEventBus(editFSWOfPageSign);

// Event type
export type SignMakerSignToEdit = {
  [signMakerSignToEdit: string]: {
    fsw: string;
    pageId: number;
    signId: string;
  };
};

// Event emitter
export const signMakerSignToEditEvent = mitt<SignMakerSignToEdit>();

// Event listener
export const signMakerSignToEdit = wrapEventBus(signMakerSignToEditEvent);
