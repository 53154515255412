import type { SignPuddleSearchEndPointResult } from "@/api/SignPuddle3Client";

export type SimplePageItemDetails = {};

export type SignPunctuationDetails = {
  fsw: {
    vertical: string;
    horizontal: string;
  };
  words: string[];
  style?: {
    fontSize?: number;
  };
};

export type SignDetails = {
  fsw: string;
  words: string[];
  style?: {
    fontSize?: number;
  };
};

type SignParagraphDetails = {
  signs: SignDetails[];
};

export type TextDetails = {
  text: string;
};

export type NumberDetails = {
  number: string;
  style?: {
    fontSize?: number;
  };
};

type ParagraphDetails = {
  text: string;
};

type PunctuationTypes = "space" | "long-space";

export type PunctuationDetails = {
  type: PunctuationTypes;
};

export type PageItemTypes =
  | "sign"
  | "signParagraph"
  | "signPunctuation"
  | "text"
  | "paragraph"
  | "punctuation"
  | "breakflow"
  | "number";

export type PageItemType = {
  id: string;
  type: PageItemTypes;
  details?:
    | SimplePageItemDetails
    | SignDetails
    | SignParagraphDetails
    | TextDetails
    | ParagraphDetails
    | PunctuationDetails
    | NumberDetails
    | SignPunctuationDetails;
};

export interface PageConfigurations {
  documentId?: string;
  id: number;
  size: {
    width: number;
    height: number;
  };
  order?: number;
  padding?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  orientation?: "portrait" | "landscape";
  writing?: {
    mode?: "vertical" | "horizontal";
    direction?: "ltr" | "rtl";
  };
  text?: PageItemType[];
}

export interface addItem {
  referenceItemId: string | undefined;
  addBefore: boolean;
  text?: string;
  signFromApi?: SignPuddleSearchEndPointResult;
}

export class Page {
  constructor(configurations: PageConfigurations) {
    this.documentId = configurations.documentId ?? "";
    this.id = configurations.id;
    this.size = configurations.size;
    this.setOrientation(configurations.orientation ?? "portrait");
    this.writing = configurations.writing = {
      mode: "vertical",
      direction: "ltr",
    };
    this.text = configurations.text ?? [];
    this.padding = configurations.padding ?? {
      top: this._defaultPaddingTop,
      right: this._defaultPaddingRight,
      bottom: this._defaultPaddingBottom,
      left: this._defaultPaddingLeft,
    };
    this.order = configurations.order ?? 0;
  }

  public readonly documentId: string;
  public readonly id: number;
  public size: PageConfigurations["size"];
  public orientation: PageConfigurations["orientation"];
  public writing: PageConfigurations["writing"];
  public text: PageItemType[];
  public padding: PageConfigurations["padding"];
  public order: number;

  private readonly _defaultPaddingTop = 10;
  private readonly _defaultPaddingRight = 10;
  private readonly _defaultPaddingBottom = 10;
  private readonly _defaultPaddingLeft = 10;

  public get configuration(): PageConfigurations {
    return {
      documentId: this.documentId,
      id: this.id,
      size: this.size,
      orientation: this.orientation,
      writing: this.writing,
      text: this.text,
    };
  }

  public get height(): number {
    return this.orientation === "portrait"
      ? this.size.height -
          ((this.padding?.top ?? 0) + (this.padding?.bottom ?? 0))
      : this.size.width; // undo after finishing breakflow. TODO: include padding in height calculation
  }

  public get width(): number {
    return this.orientation === "portrait" ? this.size.width : this.size.height;
  }

  public setOrientation(orientation: PageConfigurations["orientation"]) {
    this.orientation = orientation;
  }

  private generateRandomId(): string {
    return crypto.randomUUID().slice(0, 5);
  }

  public createSignPageItem(
    type: PageItemTypes,
    fsw: string,
    words: string[],
  ): PageItemType {
    return {
      id: this.generateRandomId(),
      type: type,
      details: {
        fsw: fsw,
        words: words,
        column: "middle",
        style: {
          fontSize: 1,
        },
      },
    };
  }

  private createSignPunctuationPageItem(
    type: PageItemTypes,
    fswVertical: string,
    fswHorizontal: string,
    words: string[],
  ): PageItemType {
    return {
      id: this.generateRandomId(),
      type: type,
      details: {
        fsw: {
          vertical: fswVertical,
          horizontal: fswHorizontal,
        },
        words: words,
        column: "middle",
        style: {
          fontSize: 1,
        },
      },
    };
  }

  /**
   * Adds a page item to the text array before or after a reference item.
   *
   * @param pageItem - The item to add to the text.
   * @param referenceItemId - The ID of the reference item. Leave undefined or pass an empty string to add the item at the end.
   * @param addBefore - If true, adds the item before the reference item; if false, adds the item after the reference item. Defaults to false.
   */
  public addToText(
    pageItem: PageItemType,
    referenceItemId: string | undefined = undefined,
    addBefore: boolean = false,
  ): void {
    console.debug(
      "Page.ts: addToText: item: ",
      pageItem,
      " referenceItemId: ",
      referenceItemId,
      " addBefore: ",
      addBefore,
    );

    // If referenceItemId is undefined or an empty string, add the item to the end of the text array
    if (undefined === referenceItemId || "" === referenceItemId) {
      this.text.push(pageItem);
      return;
    }

    // Find the index of the item with the specified ID
    const index = this.text.findIndex((item) => item.id === referenceItemId);

    // If the item with the specified ID is not found, throw an error
    if (index === -1) {
      throw new Error(
        `Page.ts: addToText: Item id '${referenceItemId}' not found.`,
      );
    }

    // Insert the new item before or after the found index based on addBefore flag
    const insertIndex = addBefore ? index : index + 1;
    this.text.splice(insertIndex, 0, pageItem);
  }

  public addSign(info: addItem) {
    const signPageItem = this.createSignPageItem(
      "sign",
      info.signFromApi?.sign ?? "",
      info.signFromApi?.terms ?? [],
    );
    this.addToText(signPageItem, info.referenceItemId, info.addBefore);
  }

  public addSignPunctuation(
    itemId: string,
    fswVertical: string,
    fswHorizontal: string,
    words: string[],
  ) {
    const signPunctuationPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      fswVertical,
      fswHorizontal,
      words,
    );
    this.addToText(signPunctuationPageItem, itemId);
  }

  public addNumber(info: addItem) {
    const numberPageItem: PageItemType = {
      id: this.generateRandomId(),
      type: "number",
      details: {
        number: info.text,
        column: "middle",
      },
    };
    this.addToText(numberPageItem, info.referenceItemId, info.addBefore);
  }

  public addSpace(info: addItem) {
    const spacePageItem: PageItemType = {
      id: this.generateRandomId(),
      type: "punctuation",
      details: {
        type: "space",
        column: "middle",
      },
    };
    this.addToText(spacePageItem, info.referenceItemId, info.addBefore);
  }

  public addLongSpace(info: addItem) {
    const longSpacePageItem: PageItemType = {
      id: this.generateRandomId(),
      type: "punctuation",
      details: {
        type: "long-space",
        column: "middle",
      },
    };
    this.addToText(longSpacePageItem, info.referenceItemId, info.addBefore);
  }

  public addBreakflow(info: addItem) {
    const breakflowPageItem: PageItemType = {
      id: this.generateRandomId(),
      type: "breakflow",
    };
    this.addToText(breakflowPageItem, info.referenceItemId, info.addBefore);
  }

  private readonly _punctuationFsw = {
    comma: {
      vertical: "S38700", // , S38700463x496 Source: Escrita de Sinais Sem Mistério, page 185.
      horizontal: "S38702", // ,
    },
    period: {
      vertical: "S38800", // . S38800464x496  Source: Escrita de Sinais Sem Mistério, page 158.
      horizontal: "S38802", // .
    },
    questionMark: {
      vertical: "S38900", // ? S38900464x493  Source: Escrita de Sinais Sem Mistério, page 245.
      horizontal: "S38902", // ?
    },
    exclamationMark: {
      vertical: "S38814", // !  S38814463x495 Source: SignPuddle https://www.signbank.org/signpuddle2.0/canvas.php?ui=1&sgn=46&sid=19060
      horizontal: "S38816", // !
    },
    openParenthesis: {
      vertical: "S38b00", // ( S38b00470x493 Source: Escrita de Sinais Sem Mistério, page 284.
      horizontal: "S38b02", // (
    },
    closeParenthesis: {
      vertical: "S38b04", // ) S38b04470x493 Source: Escrita de Sinais Sem Mistério, page 284.
      horizontal: "S38b06", // )
    },
    colon: {
      vertical: "S38a00", // : S38a00464x490  Source: Escrita de Sinais Sem Mistério, page 185.
      horizontal: "S38a02", // :
    },
  };

  public addComma(info: addItem) {
    const commaPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      this._punctuationFsw.comma.vertical,
      this._punctuationFsw.comma.horizontal,
      ["comma"],
    );
    this.addToText(commaPageItem, info.referenceItemId, info.addBefore);
  }

  public addPeriod(info: addItem) {
    const periodPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      this._punctuationFsw.period.vertical,
      this._punctuationFsw.period.horizontal,
      ["period"],
    );
    this.addToText(periodPageItem, info.referenceItemId, info.addBefore);
  }

  public addQuestionMark(info: addItem) {
    const questionMarkPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      this._punctuationFsw.questionMark.vertical,
      this._punctuationFsw.questionMark.horizontal,
      ["question mark"],
    );
    this.addToText(questionMarkPageItem, info.referenceItemId, info.addBefore);
  }

  public addExclamationMark(info: addItem) {
    const exclamationMarkPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      this._punctuationFsw.exclamationMark.vertical,
      this._punctuationFsw.exclamationMark.horizontal,
      ["exclamation mark"],
    );
    this.addToText(exclamationMarkPageItem, info.referenceItemId);
  }

  public addOpenParenthesis(info: addItem) {
    const openParenthesisPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      this._punctuationFsw.openParenthesis.vertical,
      this._punctuationFsw.openParenthesis.horizontal,
      ["open parenthesis"],
    );
    this.addToText(openParenthesisPageItem, info.referenceItemId);
  }

  public addCloseParenthesis(info: addItem) {
    const closeParenthesisPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      this._punctuationFsw.closeParenthesis.vertical,
      this._punctuationFsw.closeParenthesis.horizontal,
      ["close parenthesis"],
    );
    this.addToText(closeParenthesisPageItem, info.referenceItemId);
  }

  public addColon(info: addItem) {
    const colonPageItem = this.createSignPunctuationPageItem(
      "signPunctuation",
      this._punctuationFsw.colon.vertical,
      this._punctuationFsw.colon.horizontal,
      ["colon"],
    );
    this.addToText(colonPageItem, info.referenceItemId, info.addBefore);
  }

  public addSignParagraph(itemId: string, signs: SignDetails[]) {
    const signParagraphPageItem: PageItemType = {
      id: this.generateRandomId(),
      type: "signParagraph",
      details: {
        signs: signs,
        column: "middle",
      },
    };
    this.addToText(signParagraphPageItem, itemId);
  }

  public addText(itemId: string, text: string) {
    const textPageItem: PageItemType = {
      id: this.generateRandomId(),
      type: "text",
      details: {
        text: text,
        column: "middle",
      },
    };
    this.addToText(textPageItem, itemId);
  }

  public addParagraph(itemId: string, text: string) {
    const paragraphPageItem: PageItemType = {
      id: this.generateRandomId(),
      type: "paragraph",
      details: {
        text: text,
        column: "middle",
      },
    };
    this.addToText(paragraphPageItem, itemId);
  }

  public deletePageItem(id: string) {
    const index = this.text.findIndex((item) => item.id === id);

    if (index === -1) {
      throw new Error(`Page.ts: deletePageItem: Item id '${id}' not found.`);
    }

    this.text.splice(index, 1);
  }
}
