<script setup lang="ts">
import SignWriting from "@/components/common/SignWriting/SignWriting.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { addSigntoPage } from "@/utils/eventBus";
import { LocalHostClient } from "@/api/localHostClient";
import { useI18n } from "vue-i18n";
import type {
  SignPuddlePayload,
  SignPuddleSearchEndPointResult,
} from "@/api/SignPuddle3Client";
import { isValidFswString } from "@/utils/SignWritingUtilities";

const { t } = useI18n();

const { pageOnFocusId, localHostClient } = defineProps<{
  pageOnFocusId: number;
  localHostClient: LocalHostClient;
}>();

const emit = defineEmits(["focusOut"]);

const fswInputed = ref<string>("");
const defaultSignPuddleSearchEndPointResultItem: SignPuddlePayload<SignPuddleSearchEndPointResult> =
  {
    meta: {
      limit: 0,
      location: "",
      offset: 0,
      searchTime: "",
      totalResults: 1,
    },
    results: [
      {
        id: "",
        user: "",
        created_at: "",
        updated_at: "",
        sign: "",
        signtext: "",
        terms: [],
        text: "",
        source: "",
        detail: [],
      },
    ],
  };

function keydown(event: KeyboardEvent) {
  if (event.key === "Escape") {
    event.preventDefault();
    emit("focusOut");
  }

  if (event.key === "Enter") {
    event.preventDefault();

    if (fswInputed.value.length > 0) {
      addSigntoPage.emit("addSignToPage", {
        pageId: pageOnFocusId,
        sign: {
          ...defaultSignPuddleSearchEndPointResultItem.results[0],
          sign: fswInputed.value,
        },
      });

      localHostClient.saveSignPuddleSearchEndPointResultItem(fswInputed.value, {
        ...defaultSignPuddleSearchEndPointResultItem.results[0],
        sign: fswInputed.value,
      });
    }
  }
}

const reRender = ref(0);

const rules = [
  () => {
    if (fswInputed.value.length === 0) return true; // No error if input is empty
    return (
      (fswInputed.value.length > 0 && isValidFswString(fswInputed.value)) ||
      t("fswInputDialog.errorFswInvalid")
    );
  },
];

onMounted(() => {
  document.addEventListener("keydown", keydown);
});

onUnmounted(() => {
  document.removeEventListener("keydown", keydown);
});
</script>
<template>
  <div class="text-input-dialog-container">
    <div class="text-input-container">
      <v-text-field
        density="compact"
        label=""
        :placeholder="t('fswInputDialog.inputPlaceholder')"
        variant="solo"
        clearable
        autofocus
        v-model="fswInputed"
        @update:model-value="reRender = reRender + 1"
        @click:clear="fswInputed = ''"
        :rules="rules"
        :hint="t('fswInputDialog.inputHint')"
      ></v-text-field>
    </div>

    <div class="signwriting__container">
      <div class="signwriting__content">
        <div
          class="signwriting__signFound center min-width-and-height"
          v-show="fswInputed.length > 0 && isValidFswString(fswInputed)"
        >
          <SignWriting :font-size="1.5" :fsw="fswInputed" :key="reRender" />
        </div>
        <div
          class="signwriting__noSign center min-width-and-height"
          v-show="fswInputed.length == 0 || !isValidFswString(fswInputed)"
        >
          <SignWriting
            fsw="M543x532S34100481x483S17610527x495S17618453x495S36e00477x520S30a30488x491"
            :font-size="1.5"
            color="rgb(200,200,200)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.text-input-dialog-container {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  height: max-content;
  width: max-content;
  margin: auto;

  .text-input-container {
    min-width: 20rem;
    width: 30%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .signwriting__container {
    position: relative;
    min-width: 10rem;
    width: max-content;
    min-height: 8rem;
    height: max-content;
    margin: auto;

    .signwriting__content {
      padding: 0.5rem;
      border-radius: 3px;
      background-color: rgba(
        255,
        255,
        255,
        0.7
      ); // This is to avoid the signs having a colored background

      .center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .min-width-and-height {
        min-width: 5rem;
        min-height: 7rem;
      }

      .signwriting__signFound {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .signwriting__noSign {
        margin: auto;
      }
    }
  }
}
</style>
