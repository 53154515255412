import type ColumnItem from "./ColumnItem/model";
import { ColumnItemType } from "./ColumnItem/model";

export default class Column {
  private readonly _maxWidth: number = 0;
  private readonly _maxHeight: number = 0;
  public width: number = 110;
  public height: number = 0;
  public content: ColumnItem[] = [];
  public paddingLeft: number = 0; // TODO: Use it to centralize signs relative to the head
  private _breakPage: boolean = false;

  get breakpage(): boolean {
    return this._breakPage;
  }

  constructor(maxWidth: number, maxHeight: number) {
    this._maxWidth = maxWidth;
    this._maxHeight = maxHeight;
  }

  hasWidthAvailable(): boolean {
    const doNotExtrapolateMaxWidth =
      this.width + this.paddingLeft <= this._maxWidth;
    return doNotExtrapolateMaxWidth;
  }

  hasHeightAvailable(height: number): boolean {
    const doNotExtrapolateMaxHeight = this.height + height <= this._maxHeight;
    return doNotExtrapolateMaxHeight;
  }

  addItem(item: ColumnItem): boolean {
    if (item.type === ColumnItemType.BREAK_PAGE) {
      this._breakPage = true;
    }

    this.width = Math.max(this.width, item.width);
    this.height += item.height;
    this.content.push(item);
    return true;
  }
}
