<script lang="ts">
export const caretOnItemOfId = ref<string>("1");
</script>
<script setup lang="ts">
import Column from "./model";
import ColumnItem from "./ColumnItem/ColumnItem.vue";
import { ref } from "vue";

const { column } = defineProps<{
  column: Column;
}>();
</script>
<template>
  <div
    class="column"
    :style="{
      width: `${column.width}px`,
      height: `${column.height}px`,
      paddingLeft: `${column.paddingLeft}px`,
    }"
  >
    <ColumnItem
      v-for="(item, index) in column.content"
      :key="index"
      :item="item"
    />
  </div>
</template>
<style scoped lang="scss">
.column {
  position: relative;

  // TODO: Comment this back in once the centralization of signs relative to the header is implemented
  // &::before {
  //   content: '';
  //   position: absolute;
  //   left: 50%;
  //   display: block;
  //   width: 1px;
  //   height: 100%;
  //   background-color: red;
  // }
}
</style>
