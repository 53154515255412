<script setup lang="ts">
import BaseNavigationBar from "@/components/common/BaseNavigationBar/BaseNavigationBar.vue";
import PageComponentOngoing from "@/components/common/Page/PageComponentOngoing.vue";
import SignMaker from "./SignMaker/SignMaker.vue";
import appStore from "@/stores/AppStore";
import { DocumentEditor } from "./model";
import items_to_test_pagination from "@/assets/jsons/items_to_test_pagination.json";
import ColumnItem, {
  ColumnItemType,
} from "@/components/common/Page/Column/ColumnItem/model";
import { caretOnItemOfId } from "@/components/common/Page/Column/PageColumn.vue";
import { onMounted, onUnmounted, reactive } from "vue";
import Page from "@/components/common/Page/model";

const docData = {
  id: "1",
  name: "Document 1",
  content: items_to_test_pagination.map(
    (item) =>
      new ColumnItem(
        item.type as ColumnItemType,
        item.content as { [key: string]: any },
      ),
  ),
};

const documentEditor = reactive(
  new DocumentEditor(docData.id, docData.name, docData.content),
);

documentEditor.init();

function handleKeyDown(event: KeyboardEvent) {
  if (event.key === "ArrowUp" && caretOnItemOfId.value.length > 0) {
    event.preventDefault();
    caretOnItemOfId.value =
      documentEditor.contentHandler.getIdOfItemPreviousToItemOfId(
        caretOnItemOfId.value,
      );
    return;
  }

  if (event.key === "ArrowDown" && caretOnItemOfId.value.length > 0) {
    event.preventDefault();
    caretOnItemOfId.value =
      documentEditor.contentHandler.getIdOfItemNextToItemOfId(
        caretOnItemOfId.value,
      );
    return;
  }

  if (event.key === "ArrowLeft" && caretOnItemOfId.value.length > 0) {
    event.preventDefault();
    caretOnItemOfId.value =
      documentEditor.contentHandler.getIdOfItemPreviousToItemOfId(
        caretOnItemOfId.value,
      );
    return;
  }

  if (event.ctrlKey && event.key === "Enter") {
    event.preventDefault();
    documentEditor.addNewItemAfterItemOfId(
      new ColumnItem(ColumnItemType.BREAK_PAGE, {}),
      caretOnItemOfId.value,
    );
    caretOnItemOfId.value =
      documentEditor.contentHandler.getIdOfItemNextToItemOfId(
        caretOnItemOfId.value,
      );
    return;
  }

  if (event.key === "Enter" && caretOnItemOfId.value.length > 0) {
    event.preventDefault();
    documentEditor.addNewItemAfterItemOfId(
      new ColumnItem(ColumnItemType.BREAK_FLOW, {}),
      caretOnItemOfId.value,
    );
    caretOnItemOfId.value =
      documentEditor.contentHandler.getIdOfItemNextToItemOfId(
        caretOnItemOfId.value,
      );
    return;
  }

  if (event.key === " " && caretOnItemOfId.value.length > 0) {
    event.preventDefault();
    documentEditor.addNewItemAfterItemOfId(
      new ColumnItem(ColumnItemType.SPACE, {}),
      caretOnItemOfId.value,
    );
    caretOnItemOfId.value =
      documentEditor.contentHandler.getIdOfItemNextToItemOfId(
        caretOnItemOfId.value,
      );
    return;
  }

  if (event.key.toLowerCase() === "z" && event.ctrlKey && event.shiftKey) {
    event.preventDefault();
    documentEditor.redo();
    return;
  }

  if (event.key.toLowerCase() === "z" && event.ctrlKey && !event.shiftKey) {
    event.preventDefault();
    documentEditor.undo();
    return;
  }
}

onMounted(() =>
  document.addEventListener("keydown", (event) => handleKeyDown(event)),
);
onUnmounted(() =>
  document.removeEventListener("keydown", (event) => handleKeyDown(event)),
);
</script>
<template>
  <div
    class="document-editor-container"
    :theme="appStore.state.theme.selected.value"
  >
    <BaseNavigationBar />
    <div class="control-plane">
      <v-btn
        icon="rotate-left"
        variant="plain"
        @click="documentEditor.undo()"
        :disabled="!documentEditor.canUndo"
      />
      <v-btn
        icon="rotate-right"
        variant="plain"
        @click="documentEditor.redo()"
        :disabled="!documentEditor.canRedo"
      />
    </div>
    <div class="sheets-container" style="zoom: 100%">
      <PageComponentOngoing
        v-for="(page, index) in documentEditor.pages"
        :key="index"
        :page="page as Page"
        :documentTitle="documentEditor.title"
      />
    </div>
    <SignMaker />
  </div>
</template>
<style scoped lang="scss">
.document-editor-container {
  position: relative;
  background-color: rgb(128, 128, 128, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .control-plane {
    position: sticky;
    top: 70px;
    z-index: 1;
    width: max-content;
    margin-top: 10px;
    background-color: white;
    border-radius: 5px;
  }

  .sheets-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 4rem;
  }

  &[theme="dark"] {
    background-color: rgb(0, 0, 0, 0.06);

    .sheets-container {
      background-color: rgb(23, 23, 23, 0.9);
    }
  }
}

@media screen and (max-width: 600px) {
  .document-editor-container {
    .sheets-container {
      padding: 0;
    }
  }
}

@media print {
  .document-editor-container {
    .sheets-container {
      padding-top: 0;
      padding-bottom: 0;
      gap: 0;
    }
  }
}
</style>
