import { reactive, readonly } from "vue";

interface DocumentEditorStore {
  showNonPrintableCharacters: boolean;
  pageOnFocusId: number;
  columns: {
    active: boolean;
    showBorders: {
      left: boolean;
      middle: boolean;
      right: boolean;
    };
  };
}

function createDocumentEditorStore() {
  const state = reactive<DocumentEditorStore>({
    showNonPrintableCharacters: false,
    pageOnFocusId: 0,
    columns: {
      active: false,
      showBorders: {
        left: false,
        middle: false,
        right: false,
      },
    },
  });

  // Setters
  function setShowNonPrintableCharacters(value: boolean) {
    state.showNonPrintableCharacters = value;
  }

  function setPageOnFocusId(id: number) {
    state.pageOnFocusId = id;
  }

  function setColumnsActive(value: boolean) {
    state.columns.active = value;
  }

  function setShowBorders(side: "left" | "middle" | "right", value: boolean) {
    state.columns.showBorders[side] = value;
  }

  // Expose read-only state and setters
  return {
    state: readonly(state),
    setShowNonPrintableCharacters,
    setPageOnFocusId,
    setColumnsActive,
    setShowBorders,
  };
}

const documentEditorStore = createDocumentEditorStore();

export default documentEditorStore;
