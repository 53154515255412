import {
  SignPuddle3Client,
  type ApiConfigurations,
  type CountryPuddleResponse,
  type SignPuddlePayload,
  type SignPuddleSearchEndPointResult,
} from "@/api/SignPuddle3Client";

export class TextInputDialogModel {
  constructor(client?: SignPuddle3Client) {
    this.client = client ?? new SignPuddle3Client();
  }

  private client: SignPuddle3Client;

  public async getSignByTerm(
    term: string,
    apiConfigurations: ApiConfigurations,
  ): Promise<SignPuddlePayload<SignPuddleSearchEndPointResult>> {
    return this.client.getSignsByTerm(term, apiConfigurations);
  }

  public async getCountryPuddles(
    countryCode: string,
  ): Promise<CountryPuddleResponse> {
    return this.client.getCountryPuddles(countryCode);
  }
}
