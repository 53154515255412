<script setup lang="ts">
import type { SignPunctuationDetails } from "../Page/Page";
import SignWriting from "../SignWriting/SignWriting.vue";

type SignPunctuationProps = {
  sign: SignPunctuationDetails;
  textDirection: "vertical" | "horizontal" | undefined;
};

const props = defineProps<SignPunctuationProps>();
</script>
<template>
  <div class="sign-punctuation" :text-direction="textDirection">
    <SignWriting
      :fsw="
        props.sign.fsw[textDirection === 'vertical' ? 'vertical' : 'horizontal']
      "
      :font-size="props.sign.style?.fontSize"
    ></SignWriting>
  </div>
</template>
<style scoped lang="scss">
.sign-punctuation {
  &[text-direction="vertical"] {
    margin-top: 0.1rem;
    margin-bottom: 0.6rem;
    line-height: 0.5rem;
  }

  &[text-direction="horizontal"] {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    line-height: 0.5rem;
  }
}
</style>
@/components/common/Page/Page
