import Page from "@/components/common/Page/model";
import Column from "@/components/common/Page/Column/model";
import type ColumnItem from "@/components/common/Page/Column/ColumnItem/model";
import { ColumnItemType } from "@/components/common/Page/Column/ColumnItem/model";

class ContentHandler {
  constructor(public content: ColumnItem[]) {}

  getIdOfItemPreviousToItemOfId(id: string) {
    const index = this.content.findIndex((item) => item.id === id);
    if (index === 0) return id;
    return this.content[index - 1].id;
  }

  getIdOfItemNextToItemOfId(id: string) {
    const index = this.content.findIndex((item) => item.id === id);
    if (index === this.content.length - 1) return id;
    return this.content[index + 1].id;
  }

  deleteItemOfId(id: string) {
    const index = this.content.findIndex((item) => item.id === id);
    this.content.splice(index, 1);
  }
}

export class DocumentEditor {
  public contentHandler: ContentHandler;

  constructor(
    private _id: string,
    public title: string,
    content: ColumnItem[],
  ) {
    this.contentHandler = new ContentHandler(content);
  }

  private _PAGE_DEFAULTS = {
    width: 794,
    height: 1123,
    padding: {
      top: 20,
      left: 20,
      right: 20,
      bottom: 20,
    },
    orientation: "portrait",
    textOrientation: "vertical",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: 5,
  };

  private _pages: Page[] = [
    new Page(
      this._PAGE_DEFAULTS.width,
      this._PAGE_DEFAULTS.height,
      this._PAGE_DEFAULTS.padding,
      1,
    ),
  ];

  private _columns: Column[] = [
    new Column(this._pages[0].contentWidth, this._pages[0].contentHeight),
  ];

  public history: string[] = [];
  private _historyIndex = 0;
  private _historyLimit = 15;

  commit() {
    // If the limit of history is reached, remove the oldest item
    if (this._historyLimit === this.history.length) {
      this.history.shift();
      this._historyIndex--;
    }

    // If the current history index is not at the end of the history array,
    // remove all the items after the current index
    if (this._historyIndex < this.history.length - 1) {
      this.history = this.history.slice(0, this._historyIndex + 1);
    }

    this.history.push(JSON.stringify(this.contentHandler.content));
    this._historyIndex++;
  }

  undo() {
    if (this.canUndo) {
      this._historyIndex--;
      this.reProcess(JSON.parse(this.history[this._historyIndex]));
    }
  }

  redo() {
    if (this.canRedo) {
      this._historyIndex++;
      this.reProcess(JSON.parse(this.history[this._historyIndex]));
    }
  }

  get canUndo() {
    return this.history.length > 1 && this._historyIndex > 0;
  }

  get canRedo() {
    return (
      this.history.length > 1 && this._historyIndex < this.history.length - 1
    );
  }

  /**
   * The actions performed within this method, should be performed
   * inside the constructor. However, to activate the reactivity
   * system and tie the subsequent calls to the Proxy, I needed
   * to extract it out from there.
   */
  init() {
    this._segregateContentIntoColumns();
    this._paginateColumns();

    if (this.history.length === 0) {
      this.commit();
    }
  }

  /**
   * Similar to init(), this method needs to exist so that the
   * reactivity system can be triggered.
   *
   * Simply assigning the content to the this.content property will
   * not trigger the reactivity system because it'll not go through
   * the Proxy.
   */
  reProcess(content: ColumnItem[]) {
    this.contentHandler.content = [];

    content.forEach((item) => {
      this.contentHandler.content.push(item);
    });

    this._columns = [
      new Column(this._pages[0].contentWidth, this._pages[0].contentHeight),
    ];
    this._pages = [
      new Page(
        this._PAGE_DEFAULTS.width,
        this._PAGE_DEFAULTS.height,
        this._PAGE_DEFAULTS.padding,
        1,
      ),
    ];
    this.init();
  }

  get pages() {
    return this._pages;
  }

  private _getCurrentColumn(): Column {
    return this._columns[this._columns.length - 1];
  }

  private _getCurrentPage(): Page {
    return this._pages[this._pages.length - 1];
  }

  private _segregateContentIntoColumns() {
    this.contentHandler.content.forEach((item) => {
      if (this._getCurrentColumn().breakpage) {
        this._columns.push(
          new Column(
            this._getCurrentPage().contentWidth,
            this._getCurrentPage().contentHeight,
          ),
        );
        return;
      }

      if (item.type === ColumnItemType.BREAK_FLOW) {
        this._getCurrentColumn().addItem(item);
        this._columns.push(
          new Column(
            this._getCurrentPage().contentWidth,
            this._getCurrentPage().contentHeight,
          ),
        );
        return;
      }

      const columnWillNotFitWithinCurrentPage = !(
        this._getCurrentColumn().hasHeightAvailable(item.height) &&
        this._getCurrentPage().availableWidth()
      );
      if (columnWillNotFitWithinCurrentPage) {
        this._columns.push(
          new Column(
            this._getCurrentPage().contentWidth,
            this._getCurrentPage().contentHeight,
          ),
        );
      }

      this._getCurrentColumn().addItem(item);
    });
  }

  private _addPage(parameters: {
    width?: number;
    height?: number;
    padding?: { top?: number; right?: number; bottom?: number; left?: number };
  }) {
    this._pages.push(
      new Page(
        parameters.width || this._PAGE_DEFAULTS.width,
        parameters.height || this._PAGE_DEFAULTS.height,
        {
          top: parameters.padding?.top || this._PAGE_DEFAULTS.padding.top,
          right: parameters.padding?.right || this._PAGE_DEFAULTS.padding.right,
          bottom:
            parameters.padding?.bottom || this._PAGE_DEFAULTS.padding.bottom,
          left: parameters.padding?.left || this._PAGE_DEFAULTS.padding.left,
        },
        this._pages.length + 1,
      ),
    );
  }

  private _paginateColumns() {
    this._columns.forEach((column) => {
      if (column.breakpage) {
        this._getCurrentPage().addColumn(column);
        this._addPage({});
        return;
      }

      const columnIsTooLargeForCurrentPage = !(
        column.width <= this._getCurrentPage().availableWidth()
      );
      if (columnIsTooLargeForCurrentPage) {
        this._addPage({});
        return;
      }

      this._getCurrentPage().addColumn(column);
    });
  }

  public addNewItemAfterItemOfId(item: ColumnItem, id: string) {
    const index = this.contentHandler.content.findIndex(
      (item) => item.id === id,
    );
    this.contentHandler.content.splice(index + 1, 0, item);
    this._columns = [
      new Column(this._pages[0].contentWidth, this._pages[0].contentHeight),
    ];
    this._pages = [
      new Page(
        this._PAGE_DEFAULTS.width,
        this._PAGE_DEFAULTS.height,
        this._PAGE_DEFAULTS.padding,
        1,
      ),
    ];
    this.commit();
    this._segregateContentIntoColumns();
    this._paginateColumns();
  }
}
