<script setup lang="ts">
import { nextTick, onMounted, watch } from "vue";
import { ref } from "vue";
import type { Page } from "../Page";

const props = defineProps<{
  page: Page;
  beVisible: boolean;
  moveUp: boolean;
  updateSiblings: boolean;
  moveDown: boolean;
  moveCaretBeforeItem: HTMLElement | null;
}>();

const caret = ref<Element | null>(null);
const prevSibling = ref<Element | null>(null);
const nextSibling = ref<Element | null>(null);

const emit = defineEmits(["update:previousSibling", "update:nextSibling"]);

function getCaretIntoView() {
  /**
   * It checks if an element is in the viewport, by checking if its top, left, bottom, and right are within the window.
   *
   * It helps trigger the scrollIntoView method only when the element is not in the viewport. Which can save some performance by not causing a reflow when unnecessary.
   */
  function isElementInViewport(el: Element) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  if (!caret.value || isElementInViewport(caret.value)) return;

  caret.value.scrollIntoView({
    block: "center",
    inline: "nearest",
  });
}

watch(
  () => props.beVisible,
  (newVal) => {
    if (newVal && caret.value) {
      /* It is necessary to set the property to block, because the caret is set to none in the CSS, which makes it not visible, thus not being possible to check if it is in the viewport. */
      // (caret.value as HTMLElement).style.setProperty("display", "block");
      getCaretIntoView();
      nextTick(() => {
        updateCaretAndItsSiblings();
      });
    }
  },
);

onMounted(() => {
  getCaretIntoView();
});

function setPreviousSiblingWidthToCaret() {
  if (caret.value && prevSibling.value) {
    const siblingWidth = (prevSibling.value as HTMLElement).offsetWidth;
    // Correctly set the CSS variable without extra quotation marks
    (caret.value as HTMLElement).style.setProperty(
      "--caret-previous-sibling-width",
      `${siblingWidth}px`,
    );
  }
}

function setPreviousSiblingHeightToCaret() {
  if (caret.value && prevSibling.value) {
    const siblingHeight = (prevSibling.value as HTMLElement).offsetHeight;
    // Correctly set the CSS variable without extra quotation marks
    (caret.value as HTMLElement).style.setProperty(
      "--caret-previous-sibling-height",
      `${siblingHeight}px`,
    );
  }
}

/**
 * It updates the caret and its siblings by querying the DOM.
 * This is a hack and should be replace as soon as possible.
 *
 * Vue uses a virtual DOM and it is not exposed to the developer,
 * which means I cannot make these changes and sync them with the VDOM.
 * This can cause problems.
 */
function updateCaretAndItsSiblings() {
  const getCaret = document.querySelector('.caret[hasFocus="true"]') as Element;

  if (!getCaret) {
    throw Error("CaretComponent:updateCareAndItsSiblings: Caret not found");
  }

  caret.value = document.querySelector('.caret[hasFocus="true"]') as Element;

  prevSibling.value = caret.value.previousElementSibling;
  nextSibling.value = caret.value.nextElementSibling;
  emit("update:previousSibling", prevSibling.value);
  emit("update:nextSibling", nextSibling.value);

  setPreviousSiblingWidthToCaret();
  setPreviousSiblingHeightToCaret();
}

function moveCaretUp() {
  updateCaretAndItsSiblings();

  if (prevSibling.value && caret.value && caret.value.parentNode) {
    caret.value.parentNode.insertBefore(caret.value, prevSibling.value); // It's just a hack, should be replaced as soon as possible. Check the comment above the function updateCaretAndItsSiblings for more details.
  }

  getCaretIntoView();
}

function moveCaretDown() {
  updateCaretAndItsSiblings();

  if (nextSibling.value && caret.value && caret.value.parentNode) {
    caret.value.parentNode.insertBefore(nextSibling.value, caret.value); // Check the comment above the function updateCaretAndItsSiblings for more details.
  }

  getCaretIntoView();
}

function moveCaretBefore(item: HTMLElement | null) {
  if (props.moveCaretBeforeItem && caret.value && caret.value.parentNode) {
    caret.value.parentNode.insertBefore(caret.value, item); // Check the comment above the function updateCaretAndItsSiblings for more details.
  }
}

watch(
  () => props.updateSiblings,
  () => {
    updateCaretAndItsSiblings();
  },
);

watch(
  () => props.moveUp,
  () => {
    moveCaretUp();
  },
);

watch(
  () => props.moveDown,
  () => {
    moveCaretDown();
  },
);

watch(
  () => props.moveCaretBeforeItem,
  () => {
    moveCaretBefore(props.moveCaretBeforeItem);
  },
);
</script>
<template>
  <div class="caret" v-show="props.beVisible" :hasFocus="beVisible" ref="caret">
    <div class="caret-animation" :text-direction="page.writing?.mode"></div>
  </div>
</template>
<style lang="scss" scoped>
.caret {
  position: relative; // This relativeness freeze the caret-animation at the right place, instead of staying on top of everything when the user scrolls the page to the side in the mobile view.
  .caret-animation {
    &::after {
      position: absolute;
      content: "";
      display: block;
      height: 1px;
      width: var(--caret-previous-sibling-width);
      background-color: black;
      animation: pulse 1s infinite;

      @keyframes pulse {
        0% {
          opacity: 1;
        }
        40% {
          opacity: 0;
        }
      }
    }

    &[text-direction="vertical"] {
      &::after {
        min-width: 3.5rem;
        width: 1px;
      }
    }

    &[text-direction="horizontal"] {
      &::after {
        width: 1px;
        min-height: 2.5rem;
        height: var(--caret-previous-sibling-height);
      }
    }
  }
}
</style>
