import type Column from "./Column/model";

export default class Page {
  public width: number = 0;
  public height: number = 0;
  public paddingTop: number = 0;
  public paddingRight: number = 0;
  public paddingBottom: number = 0;
  public paddingLeft: number = 0;
  public order: number = 0;
  public footer: {
    documentTitle: boolean;
    pageNumber: boolean;
    applicationTitle: boolean;
  } = {
    documentTitle: true,
    pageNumber: true,
    applicationTitle: true,
  };

  private readonly _footerHeight: number = 40;
  public readonly contentWidth: number = 0;
  public readonly contentHeight: number = 0;

  private _columns: Column[] = [];
  private _columnsTotalWidth: number = 0;
  private _columnsTotalHeight: number = 0;

  constructor(
    width: number,
    height: number,
    padding: { top: number; right: number; bottom: number; left: number },
    order?: number,
  ) {
    this.width = width;
    this.height = height;
    this.paddingTop = padding.top;
    this.paddingRight = padding.right;
    this.paddingBottom = padding.bottom + this._footerHeight;
    this.paddingLeft = padding.left;
    this.contentWidth = this.width - this.paddingLeft - this.paddingRight;
    this.contentHeight = this.height - this.paddingTop - this.paddingBottom;
    this.order = order || 0;
  }

  get columns() {
    return this._columns;
  }

  addColumn(column: Column) {
    this._columns.push(column);
    this._columnsTotalWidth += column.width;
    this._columnsTotalHeight += column.height;
  }

  availableWidth() {
    return this.contentWidth - this._columnsTotalWidth;
  }

  availableHeight() {
    return this.contentHeight - this._columnsTotalHeight;
  }
}
