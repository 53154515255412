import appStore from "@/stores/AppStore";
import FeatureToggle from "@/utils/FeatureToggle";
import { watch, type WritableComputedRef } from "vue";
import { createI18n, type I18n } from "vue-i18n";
import en from "../assets/jsons/locale/en.json";
import pt from "../assets/jsons/locale/pt.json";

/**
 * Lazy load locale messages files.
 *
 * @source https://vue-i18n.intlify.dev/guide/advanced/lazy.html
 * @param i18n
 * @param locale
 */
export async function loadLocaleMessages(i18n: I18n, locale: string) {
  let messages;
  // load locale messages with dynamic import
  switch (locale) {
    case "pt":
      messages = await import("../assets/jsons/locale/pt.json");
      break;
    default:
      messages = await import("../assets/jsons/locale/en.json");
      break;
  }

  i18n.global.setLocaleMessage(locale, messages.default);
}

let i18n: I18n;

if (FeatureToggle.isLazyLoadingI18nMessagesEnabled()) {
  i18n = createI18n({
    legacy: false,
    locale: appStore.state.language.selected.code as "en" | "pt",
    fallbackLocale: "en",
  });
} else {
  i18n = createI18n({
    legacy: false,
    locale: appStore.state.language.selected.code as "en" | "pt",
    fallbackLocale: "en",
    messages: {
      en,
      pt,
    },
  });
}

loadLocaleMessages(
  i18n,
  (i18n.global.locale as WritableComputedRef<string>).value,
);

watch(
  () => appStore.state.language.selected.code,
  (code) => {
    (i18n.global.locale as WritableComputedRef<string>).value = code as
      | "en"
      | "pt"; // set locale
    loadLocaleMessages(i18n, code); // load language translation json file
  },
);

export default i18n;
