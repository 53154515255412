<script setup lang="ts">
import type { NumberDetails } from "../Page/Page";

type NumberComponentProps = {
  number: NumberDetails;
  textDirection: "vertical" | "horizontal" | undefined;
};

const props = defineProps<NumberComponentProps>();
</script>
<template>
  <div class="number-component" :text-direction="textDirection">
    <p>{{ props.number.number }}</p>
  </div>
</template>
<style scoped lang="scss">
.number-component {
  text-align: center;

  &[text-direction="horizontal"] {
    height: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>
