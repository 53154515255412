<script setup lang="ts">
import SignWriting from "@/components/common/SignWriting/SignWriting.vue";
import type { TextInputDialogModel } from "./model/TextInputDialogModel";
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import type {
  ApiConfigurations,
  SignPuddlePayload,
  SignPuddleSearchEndPointResult,
  PuddleInfo,
} from "@/api/SignPuddle3Client";
import { addSigntoPage } from "@/utils/eventBus";
import { LocalHostClient } from "@/api/localHostClient";
import { useI18n } from "vue-i18n";
import signpuddle_countries from "@/assets/jsons/signpuddle_countries.json";
import { SignPuddleMatch } from "@/api/SignPuddle3Client";

const { t } = useI18n();

const { model, pageOnFocusId, localHostClient } = defineProps<{
  model: TextInputDialogModel;
  pageOnFocusId: number;
  localHostClient: LocalHostClient;
}>();

const emit = defineEmits(["focusOut"]);

const apiConfigurations = reactive<ApiConfigurations>({});

const apiResults = reactive<SignPuddlePayload<SignPuddleSearchEndPointResult>>({
  meta: {
    limit: 0,
    location: "",
    offset: 0,
    searchTime: "",
    totalResults: 0,
  },
  results: [],
});

const currentSignIndex = ref(0);
const signToShow = ref<SignPuddleSearchEndPointResult | undefined>();
const searchTerm = ref<string>("");

const hasPreviousSign = computed(() => currentSignIndex.value > 0);
const hasNextSign = computed(
  () => currentSignIndex.value < apiResults.results.length - 1,
);
const lastResultIsfromLocalStorage = ref(false);

const isLoading = ref(false);

async function loadingWrapper(
  doSomething: () => Promise<unknown>,
  ifError: (error: unknown) => void,
) {
  try {
    isLoading.value = true;
    await doSomething();
  } catch (error) {
    ifError(error);
  } finally {
    isLoading.value = false;
  }
}

function clear() {
  apiResults.results = [];
  signToShow.value = undefined;
  currentSignIndex.value = 0;
  lastResultIsfromLocalStorage.value = false;
}

async function search(term: string | null) {
  clear();
  if (!term || term.length == 0) {
    console.debug("TextInputDialog: search: No search term provided");
    return;
  }

  // Check Local Storage First
  const resultFromLocalStorage =
    localHostClient.getSignPuddleSearchEndPointResultItem(term);

  // If found in Local Storage, use it
  if (resultFromLocalStorage) {
    console.debug(
      "TextInputDialog: search: resultFromLocalStorage",
      resultFromLocalStorage,
    );

    lastResultIsfromLocalStorage.value = true;

    const oneResultApiResults = {
      meta: {
        limit: 0,
        location: "",
        offset: 0,
        searchTime: "",
        totalResults: 1,
      },
      results: [resultFromLocalStorage],
    };

    Object.assign(apiResults, oneResultApiResults);

    signToShow.value = resultFromLocalStorage;
  } else {
    // If not found in Local Storage, call API
    callApiForSign(term);
  }
}

function callApiForSign(
  term: string,
  resultFromLocalStorage?: SignPuddleSearchEndPointResult,
) {
  loadingWrapper(
    async () => {
      const results = await model.getSignByTerm(term, apiConfigurations);

      if (results.meta.totalResults === 0 && !resultFromLocalStorage) {
        console.debug("TextInputDialog: callApiForSign: No results found");
        clear();
        return;
      }

      // Filter out results that don't have a sign from API results
      results.results = results.results.filter(
        (result) => result.sign.length > 0,
      );

      // Remove the result from local storage if it is in the API results
      if (resultFromLocalStorage) {
        results.results.filter(
          (result) => result.id !== resultFromLocalStorage.id,
        );

        // Add the result from local storage to the beginning of the API results
        results.results.unshift(resultFromLocalStorage);
      }

      Object.assign(apiResults, results);

      if (apiResults.results[currentSignIndex.value].sign.length > 0) {
        lastResultIsfromLocalStorage.value = false;
        signToShow.value = apiResults.results[currentSignIndex.value];
      }
    },
    (error) => {
      throw Error("TextInputDialog:search: An error occurred: " + error);
    },
  );
}

let timeout: any = null;

/**
 * Debounce function, to avoid making too many requests. It will wait for the
 * user to stop typing for a certain amount of time before making the request.
 *
 * @param func Function to be debounced
 * @param delay Delay in milliseconds
 */
function debounce(func: Function, delay: number) {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
}

function debouncedSearch(term: string) {
  debounce(() => {
    search(term);
  }, 400);
}

function previousSign() {
  // Move to the previous sign if there is one
  if (currentSignIndex.value > 0) {
    currentSignIndex.value--;
    signToShow.value = apiResults.results[currentSignIndex.value];
  }
}

function nexSign() {
  if (lastResultIsfromLocalStorage.value) {
    callApiForSign(
      searchTerm.value,
      apiResults.results[currentSignIndex.value],
    );
  }

  // Move to the next sign if there is one
  if (currentSignIndex.value < apiResults.results.length - 1) {
    currentSignIndex.value++;
    signToShow.value = apiResults.results[currentSignIndex.value];
  }
}

function debounceItemSelection() {
  debounce(() => {
    selectItem();
  }, 200);
}

function selectItem() {
  if (signToShow.value && signToShow.value.sign.length > 0) {
    addSigntoPage.emit("addSignToPage", {
      pageId: pageOnFocusId,
      sign: apiResults.results[currentSignIndex.value],
    });

    localHostClient.saveSignPuddleSearchEndPointResultItem(
      searchTerm.value,
      apiResults.results[currentSignIndex.value],
    );
  }
}

function keydown(event: KeyboardEvent) {
  if (event.key === "Escape") {
    event.preventDefault();
    emit("focusOut");
  }

  if (event.key === "ArrowUp") {
    event.preventDefault();
    previousSign();
  }

  if (event.key === "ArrowDown") {
    event.preventDefault();
    nexSign();
  }

  if (event.key === "Enter") {
    event.preventDefault();
    selectItem();
  }
}

const showFilters = ref(false);

function expandFilters() {
  showFilters.value = !showFilters.value;
}

function itemPropsCountryCode(item: {
  name: string;
  code: string;
  qqq: string;
}) {
  return {
    title: item.code,
    subtitle: item.name,
  };
}

const wordMatch = ref<{
  title: string;
  value: SignPuddleMatch;
}>({
  title: "Start of word",
  value: SignPuddleMatch.Start,
});

function setWordMatch(value: string | null) {
  if (!value) {
    return;
  }

  switch (value) {
    case "Any part of word":
      wordMatch.value = {
        title: "Any part of word",
        value: SignPuddleMatch.Anywhere,
      };
      break;
    case "Start of word":
      wordMatch.value = {
        title: "Start of word",
        value: SignPuddleMatch.Start,
      };
      break;
    case "Exact word":
      wordMatch.value = {
        title: "Exact word",
        value: SignPuddleMatch.Exact,
      };
      break;
  }

  filtersChanged.value = true;
}

const countryCode = ref<{
  name: string;
  code: string;
} | null>();

function setCountryCode(value: { name: string; code: string } | null) {
  if (!value) {
    return;
  }

  apiConfigurations.countryCode = value.code;

  const countryPuddles = localHostClient.getCountryPuddles(value.code);

  if (countryPuddles) {
    puddles.value = countryPuddles;
    selectedPuddle.value = countryPuddles[0];
    filtersChanged.value = true;
    return;
  }

  callApiForCountryPuddles();
  filtersChanged.value = true;
}

const puddles = ref<PuddleInfo[]>();
const selectedPuddle = ref<PuddleInfo | undefined>();
const loadingPuddles = ref(false);

async function callApiForCountryPuddles() {
  if (!apiConfigurations.countryCode) {
    return;
  }

  loadingPuddles.value = true;

  const apiResult = await model.getCountryPuddles(
    apiConfigurations.countryCode,
  );

  puddles.value = apiResult.results.flatMap((result) => result.puddles);

  localHostClient.setCountryPuddles(
    apiConfigurations.countryCode,
    puddles.value,
  );

  selectedPuddle.value = puddles.value[0];

  loadingPuddles.value = false;
}

function itemPropsPuddles(item: PuddleInfo) {
  return {
    title: item.name,
    subtitle: "",
  };
}

function setPuddleCode(value: { name: string; code: string }) {
  if (!value) {
    return;
  }

  apiConfigurations.puddleCode = value.code;
  filtersChanged.value = true;
}

const filtersChanged = ref(false);

function applyFilters() {
  apiConfigurations.match = wordMatch.value?.value;
  apiConfigurations.countryCode = countryCode.value?.code;
  apiConfigurations.puddleCode = selectedPuddle.value?.code;

  localHostClient.setUserPreferences({
    country: {
      name: countryCode.value!.name,
      code: countryCode.value!.code,
      puddle: selectedPuddle.value ?? {
        name: "",
        code: "",
        qqq: "",
        namespace: "",
        icon: "",
      },
    },
    wordMatch: wordMatch.value?.value,
  });

  clear();

  filtersChanged.value = false;
  expandFilters();
}

function initializeFilters() {
  const userPreferences = localHostClient.getUserPreferences();

  if (userPreferences) {
    countryCode.value = {
      name: userPreferences.country.name,
      code: userPreferences.country.code,
    };

    selectedPuddle.value = userPreferences.country.puddle;

    const countryPuddles = localHostClient.getCountryPuddles(
      countryCode.value!.code,
    );

    if (countryPuddles) {
      puddles.value = countryPuddles;
    }
  }
}

onMounted(() => {
  document.addEventListener("keydown", keydown);
  signToShow.value = undefined;
  initializeFilters();
});

onUnmounted(() => {
  document.removeEventListener("keydown", keydown);
});
</script>
<template>
  <div class="text-input-dialog-container">
    <div v-show="!showFilters">
      <div class="text-input-container">
        <v-text-field
          density="compact"
          label=""
          :placeholder="t('textInputDialog.searchPlaceholder')"
          variant="outlined"
          hide-details
          clearable
          autofocus
          v-model="searchTerm"
          @update:model-value="debouncedSearch($event)"
          @click:clear="clear"
        >
          <template #append>
            <v-btn icon @click="expandFilters" size="small" variant="outlined">
              <v-icon>sliders</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>

      <div class="signwriting__container">
        <div class="signwriting__content">
          <div
            class="signwriting__loading center min-width-and-height"
            v-show="isLoading"
          >
            <v-progress-circular indeterminate class="centralize" />
          </div>
          <div
            class="signwriting__signFound center min-width-and-height"
            v-show="
              !isLoading &&
              undefined != signToShow &&
              signToShow?.sign.length > 0
            "
          >
            <div
              class="signFound__sign"
              @click="debounceItemSelection"
              @touchstart="debounceItemSelection"
            >
              <SignWriting
                :font-size="1.5"
                :fsw="signToShow?.sign || ''"
                :key="currentSignIndex"
              />
            </div>
            <div class="signFound__actions">
              <div class="actions__previous-sign">
                <v-btn
                  icon
                  size="small"
                  variant="plain"
                  :disabled="!hasPreviousSign"
                  @click="previousSign"
                >
                  <v-icon>circle-arrow-up</v-icon>
                </v-btn>
              </div>
              <p class="total-signs">
                {{
                  currentSignIndex +
                  1 +
                  "/" +
                  (isLoading ? "..." : apiResults.results.length)
                }}
              </p>
              <div class="actions__next-sign">
                <v-btn
                  icon
                  variant="plain"
                  size="small"
                  :disabled="
                    lastResultIsfromLocalStorage ? false : !hasNextSign
                  "
                  @click="nexSign"
                >
                  <v-icon>circle-arrow-down</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div
            class="signwriting__noSignFound center min-width-and-height"
            v-show="!isLoading && undefined == signToShow"
          >
            <SignWriting
              fsw="M543x532S34100481x483S17610527x495S17618453x495S36e00477x520S30a30488x491"
              :font-size="1.5"
              color="rgb(200,200,200)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-show="showFilters">
      <div class="text-input__filters">
        <div
          style="
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-bottom: 1rem;
          "
        >
          <v-btn
            @click="showFilters = !showFilters"
            icon="arrow-left"
            size="small"
            variant="plain"
          ></v-btn>
          <p class="filters__title">Filter By</p>
        </div>
        <div class="filters__section">
          <p class="filters__subtitle">Word Match</p>
          <v-select
            density="compact"
            variant="outlined"
            label=""
            v-model="wordMatch.title"
            @update:model-value="setWordMatch"
            :items="['Any part of word', 'Start of word', 'Exact word']"
            hide-details
          />
        </div>
        <div class="filters__section">
          <p class="filters__subtitle">Country</p>
          <div style="display: flex; justify-content: space-between; gap: 1rem">
            <v-select
              density="compact"
              variant="outlined"
              placeholder="Code"
              v-model="countryCode"
              :item-props="itemPropsCountryCode"
              :items="signpuddle_countries"
              hide-details
              width="2rem"
              @update:model-value="setCountryCode"
            >
            </v-select>
            <div
              style="
                width: 11rem;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-progress-circular
                v-show="loadingPuddles"
                indeterminate
                class="centralize"
              />
              <v-select
                v-show="!loadingPuddles"
                density="compact"
                variant="outlined"
                placeholder="Puddle"
                v-model="selectedPuddle"
                :item-props="itemPropsPuddles"
                :items="puddles"
                @update:model-value="setPuddleCode"
                hide-details
              />
            </div>
          </div>
        </div>
        <div class="filters__section" v-if="false">
          <p class="filters__subtitle">Author</p>
          <v-checkbox density="compact" hide-details style="font-size: 0.8rem">
            <template #label>
              <p style="font-size: 0.9rem">Only signs with authors</p>
            </template>
          </v-checkbox>
          <v-text-field
            density="compact"
            label=""
            :placeholder="t('textInputDialog.searchPlaceholder')"
            variant="outlined"
            hide-details
            clearable
          />
        </div>
        <div
          class="filters__actions"
          style="display: flex; justify-content: right"
        >
          <v-btn
            @click="applyFilters"
            size="small"
            style="margin-top: 1rem"
            variant="outlined"
            :disabled="!filtersChanged"
          >
            Apply
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.text-input-dialog-container {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  height: max-content;
  width: 20rem;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .text-input-container {
    margin: auto;
  }

  .text-input__filters {
    margin-top: 0.5rem;

    .filters__title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .filters__section {
      margin-bottom: 0.5rem;

      .filters__subtitle {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }

  .signwriting__container {
    min-width: 7rem;
    width: 100%;
    min-height: 8rem;
    height: max-content;
    display: flex;
    align-items: center;

    .signwriting__content {
      display: flex;
      align-items: center;
      margin: auto;
      padding-top: 1rem;
      width: 100%;

      .center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .signwriting__loading {
        width: 100%;
      }

      .signwriting__signFound {
        display: grid;
        grid-template-columns: 90% 10%;
        justify-content: space-between;
        width: 100%;

        .signFound__sign {
          &:hover {
            cursor: pointer;
          }
        }

        .signFound__actions {
          display: flex;
          flex-direction: column;

          .total-signs {
            font-size: 0.7rem;
            color: rgba(0, 0, 0, 0.5);
            text-align: center;
          }
        }

        .signFound__metadata {
          width: 15rem;
          word-wrap: break-word;
          font-size: 0.7rem;
        }
      }

      .signwriting__noSignFound {
        margin: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .text-input-dialog-container {
    min-width: 80%;
  }
}
</style>
