<script setup lang="ts">
import { computed } from "vue";
import { isFswType } from "@/utils/SignWritingUtilities";
import { fsw as fontTtf } from "@sutton-signwriting/font-ttf";
import FeatureToggle from "@/utils/FeatureToggle";

const props = defineProps<{
  fsw: string;
  fontSize?: number;
  color?: string;
}>();

const signToShow = computed(() => {
  const isPunctuation = isFswType(props.fsw, "punctuation");

  if (FeatureToggle.isSignWritingTTFontSignGenerationEnabled()) {
    return isPunctuation
      ? fontTtf.symbolSvg(props.fsw)
      : fontTtf.signSvg(props.fsw);
  }

  return isPunctuation
    ? `<fsw-symbol symbol="${props.fsw}" style="font-size: ${props.fontSize ?? 0.7}rem; color: ${props.color};"></fsw-symbol>`
    : `<fsw-sign sign="${props.fsw}" style="font-size: ${props.fontSize ?? 0.7}rem; color: ${props.color};"></fsw-sign>`;
});
</script>
<template>
  <div class="sign" v-html="signToShow" aria-hidden="true"></div>
</template>
<style scoped lang="scss">
.sign {
  text-align: center;
}
</style>
