import { defineComponent, type Ref, type Component, h } from "vue";
import type { ComponentProps } from "vue-component-type-helpers";

/**
 *
 * It's used to trigger a re-rendering of the components by changing their ":key" value.
 * Once the key is changed, Vue will throw away the current component and re-render it. In other words, Vue
 * will instantiate the component again.
 * @tutorial https://michaelnthiessen.com/force-re-render/#key-changing-to-force-a-component-refresh
 *
 * @param componentKey - The ref variable used by the component as its id.
 *
 */
export function triggerComponentReRendering(componentKey: Ref<number>): void {
  componentKey.value += 1;
}

/**
 * Render a component with the given props.
 *
 * @template T The component type
 *
 * @param content The component to render
 * @param props The props to pass to the component
 *
 * @returns A Vue component definition that renders the given component with the given props
 */
export function render<T extends Component>(
  content: T,
  props: Partial<ComponentProps<T>> = {},
): ReturnType<typeof defineComponent> {
  return defineComponent({
    render() {
      return h(content, props);
    },
  });
}
