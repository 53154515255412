<script setup lang="ts">
import { computed, nextTick, ref, type Ref } from "vue";
import LogoItem from "@/components/common/Logo/LogoItem.vue";
import FeatureToggle from "@/utils/FeatureToggle";
import appStore, { ThemeOptions } from "@/stores/AppStore";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const selectedLanguage = ref();

async function onLanguageUpdate(language: string) {
  appStore.setSelectedLanguage(language);

  // Without nextTick, selectedTheme is not updated according to the newly selected language
  await nextTick(() => {
    selectedTheme.value = getTheme();
  });
}

const themeOptions = computed(() => {
  return {
    system: t("app.settings.theme.system"),
    dark: t("app.settings.theme.dark"),
    light: t("app.settings.theme.light"),
  };
});

function getTheme() {
  if (appStore.state.theme.selected.source === ThemeOptions.SYSTEM) {
    return themeOptions.value.system;
  }

  if (appStore.state.theme.selected.value === ThemeOptions.DARK) {
    return themeOptions.value.dark;
  }

  if (appStore.state.theme.selected.value === ThemeOptions.LIGHT) {
    return themeOptions.value.light;
  }
}

function setPreferredTheme(theme: string) {
  let selectedTheme = ThemeOptions.SYSTEM;

  switch (theme) {
    case themeOptions.value.system:
      selectedTheme = ThemeOptions.SYSTEM;
      break;
    case themeOptions.value.dark:
      selectedTheme = ThemeOptions.DARK;
      break;
    case themeOptions.value.light:
      selectedTheme = ThemeOptions.LIGHT;
      break;
  }

  appStore.setSelectedTheme(selectedTheme);
}

const selectedTheme = ref();

function open() {
  selectedLanguage.value = appStore.state.language.selected.name;
  selectedTheme.value = getTheme();
}

function close(activator: Ref<boolean>) {
  activator.value = false;

  selectedLanguage.value = appStore.state.language.selected.name;
}
</script>
<template>
  <div class="topbar__container" :theme="appStore.state.theme.selected.value">
    <div class="logo">
      <v-badge color="warning" content="beta" floating>
        <LogoItem />
      </v-badge>
    </div>
    <div class="topbar__content">
      <slot></slot>
    </div>
    <div class="settings" v-if="FeatureToggle.isSettingsEnabled">
      <v-btn variant="plain" rounded @click="open">
        <v-icon>cog</v-icon>
        <v-dialog activator="parent" max-width="300" persistent>
          <template v-slot:default="{ isActive }">
            <v-card rounded="lg">
              <v-card-title class="d-flex justify-space-between align-center">
                <div>
                  <h2 class="text-h5 ps-2">{{ t("app.settings.title") }}</h2>
                  <p class="text-subtitle-2 ps-2" v-if="false">
                    Powered by SingPuddle - Sign Maker
                  </p>
                </div>

                <v-btn
                  icon="close"
                  variant="text"
                  @click="close(isActive)"
                ></v-btn>
              </v-card-title>
              <v-card-subtitle> </v-card-subtitle>

              <v-divider class="mb-4"></v-divider>

              <v-card-text>
                <div class="configurations">
                  <div
                    class="configuration"
                    v-if="FeatureToggle.isSettingsLanguageEnabled"
                  >
                    <p>{{ t("app.settings.language") }}</p>
                    <v-select
                      variant="outlined"
                      hide-details
                      max-width="150"
                      v-model="selectedLanguage"
                      @update:model-value="onLanguageUpdate($event)"
                      :items="
                        appStore.state.language.available.map(
                          (language) => language.name,
                        )
                      "
                    />
                  </div>
                  <div
                    class="configuration"
                    v-if="FeatureToggle.isSettingsThemeEnabled"
                  >
                    <p>{{ t("app.settings.theme.title") }}</p>
                    <v-select
                      variant="outlined"
                      hide-details
                      max-width="150"
                      v-model="selectedTheme"
                      @update:model-value="setPreferredTheme($event)"
                      :items="[
                        themeOptions.system,
                        themeOptions.dark,
                        themeOptions.light,
                      ]"
                    />
                  </div>
                </div>
              </v-card-text>

              <v-divider class="mt-2"></v-divider>

              <v-card-actions class="my-2 d-flex justify-end">
                <v-btn class="text-none" rounded="xl" @click="close(isActive)"
                  ><template #default>
                    {{ t("app.settings.closeBtn") }}
                  </template></v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-btn>
    </div>
  </div>
</template>
<style scoped lang="scss">
.topbar__container {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0.45rem 1rem 0.4rem 1.3rem;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
  min-height: 4rem;
  display: grid;
  grid-template-columns: min-content auto auto;

  .logo {
    font-size: 1.25rem;
    margin-right: 2rem;
    justify-self: start;
    align-self: center;
  }

  .settings {
    justify-self: self-end;
    align-self: center;
  }

  &[theme="dark"] {
    background-color: #171717;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.configuration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .topbar__container {
    overflow: hidden;
    .logo {
      font-size: large;
    }

    .topbar__content {
      margin-left: 0.5rem;
      width: min-content;
    }
  }
}
</style>
