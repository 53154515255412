import { reactive, readonly } from "vue";

interface AppStore {
  theme: {
    selected: {
      source: "system" | "user";
      value: "dark" | "light";
    };
    available: ("dark" | "light" | "system")[];
  };
  language: {
    selected: LanguageOption;
    available: LanguageOption[];
  };
}

type LanguageOption = {
  code: string;
  name: string;
};

const languageOptions: LanguageOption[] = [
  { code: "en", name: "English" },
  // { code: "es", name: "Español" },
  { code: "pt", name: "Português" },
];

function getLanguageFromLocalStorage(): LanguageOption | null {
  const language = localStorage.getItem("language");

  if (language) {
    console.debug("AppStore: Language fetched from local storage:", language);
    return JSON.parse(language) as LanguageOption;
  }

  return null;
}

function getLanguageFromUserAgent(): LanguageOption | null {
  const language = navigator.language.split("-")[0];

  return (
    languageOptions.find((l) => {
      if (l.code === language) {
        console.debug("AppStore: Language fetched from user agent:", l.code);
        return true;
      }
      return false;
    }) || null
  );
}

type ThemeSelection = {
  source: "system" | "user";
  value: "dark" | "light";
};

export enum ThemeOptions {
  DARK = "dark",
  LIGHT = "light",
  SYSTEM = "system",
}

function getPreferredThemeFromSystem(): ThemeSelection {
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    console.debug("AppStore: Theme fetched from system: dark");
    return { source: "system", value: "dark" };
  }

  console.debug("AppStore: Theme fetched from system: light");
  return { source: "system", value: "light" };
}

function getPreferredThemeFromLocalStorage(): ThemeSelection | null {
  const theme = localStorage.getItem("theme");

  if (theme) {
    console.debug("AppStore: Theme fetched from local storage:", theme);
    return JSON.parse(theme) as ThemeSelection;
  }

  return null;
}

function createAppStore() {
  const state = reactive<AppStore>({
    theme: {
      selected:
        getPreferredThemeFromLocalStorage() ?? getPreferredThemeFromSystem(),
      available: ["dark", "light", "system"],
    },
    language: {
      selected:
        getLanguageFromLocalStorage() ||
        getLanguageFromUserAgent() ||
        languageOptions[0],
      available: languageOptions,
    },
  });

  // Setters
  function setSelectedTheme(value: ThemeOptions) {
    switch (value) {
      case ThemeOptions.DARK:
        state.theme.selected = { source: "user", value: "dark" };
        console.debug(
          "AppStore: setSelectedTheme: Set theme key in local storage for user theme selection): dark",
        );
        localStorage.setItem("theme", JSON.stringify(state.theme.selected));
        break;
      case ThemeOptions.LIGHT:
        state.theme.selected = { source: "user", value: "light" };
        console.debug(
          "AppStore: setSelectedTheme: Set theme key in local storage for user theme selection): light",
        );
        localStorage.setItem("theme", JSON.stringify(state.theme.selected));
        break;
      case ThemeOptions.SYSTEM:
        state.theme.selected = getPreferredThemeFromSystem();
        console.debug(
          "AppStore: setSelectedTheme: Remove theme key from local storage for system theme selection.",
        );
        localStorage.removeItem("theme");
        break;
    }
  }

  function setSelectedLanguage(lang: string) {
    const value = languageOptions.find(
      (l) => l.code === lang || l.name === lang,
    );

    if (value && languageOptions.includes(value)) {
      localStorage.setItem("language", JSON.stringify(value));
      state.language.selected = value;
    }
  }

  // Expose read-only state and setters
  return {
    state: readonly(state),
    setSelectedTheme,
    setSelectedLanguage,
  };
}

const appStore = createAppStore();

export default appStore;
