<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { signMakerSignToEdit, editFSWOfPageSign } from "@/utils/eventBus";

const signId = ref("");
const pageId = ref(0);
const fsw = ref("");
const overlay = ref(false);

function save(newFsw: string) {
  editFSWOfPageSign.emit("editFswOfPageSign", {
    fsw: newFsw,
    signId: signId.value,
    pageId: pageId.value,
  });

  overlay.value = false;
}

function listenToIframeMessages(event: any) {
  if (event.origin !== "https://www.sutton-signwriting.io") return;

  switch (event.data.signmaker) {
    case "save":
      save(event.data.fsw);
      break;
    case "cancel":
      overlay.value = false;
      break;
    default:
      break;
  }
}

signMakerSignToEdit({
  signMakerSignToEdit: (eventData: {
    fsw: string;
    signId: string;
    pageId: number;
  }) => {
    const IS_MOBILE = screen.width < 600;

    if (IS_MOBILE) return;

    signId.value = eventData.signId;
    pageId.value = eventData.pageId;
    fsw.value = eventData.fsw;
    overlay.value = true;
  },
});

onMounted(() => {
  window.addEventListener("message", (event) => listenToIframeMessages(event));
});

onUnmounted(() => {
  window.removeEventListener("message", (event) =>
    listenToIframeMessages(event),
  );
});
</script>
<template>
  <v-overlay v-model="overlay" content-class="signmaker-overlay-content">
    <div class="signmaker-container">
      <h2 style="margin-bottom: 0.5rem">SignMaker 2022</h2>
      <iframe
        style="width: 100%; height: 430px; border: none"
        :src="`https://www.sutton-signwriting.io/signmaker/index.html#?fsw=${fsw}`"
      ></iframe>
    </div>
  </v-overlay>
</template>
<style lang="scss">
.signmaker-overlay-content {
  position: relative;
  width: 100%; // Necessary to place signmaker-container in the center
}

.signmaker-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 700px;
  height: 500px;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  background-color: white;
}
</style>
