<script setup lang="ts">
import {
  defineComponent,
  ref,
  type Component,
  markRaw,
  h,
  shallowRef,
  computed,
  onMounted,
  nextTick,
  watch,
} from "vue";
import type {
  NumberDetails,
  Page,
  PageItemType,
  PunctuationDetails,
  SignDetails,
  SignPunctuationDetails,
  addItem,
} from "./Page";
import SignColumn from "../SignColumn/SignColumn.vue";
import Caret from "./CaretComponent/CaretComponent.vue";
import {
  addNotificationToAppAlertEvent,
  pageComponentAddSign,
  pageComponentEditSignFsw,
  signMakerSignToEditEvent,
} from "@/utils/eventBus";
import { type SignPuddleSearchEndPointResult } from "@/api/SignPuddle3Client";
import type { ComponentProps } from "vue-component-type-helpers";
import NumberComponent from "@/components/common/NumberComponent/NumberComponent.vue";
import PunctuationComponent from "@/components/common/PunctuationComponent/PunctuationComponent.vue";
import SignPunctuation from "@/components/common/SignPunctuation/SignPunctuation.vue";
import SignComponent from "../SignComponent/SignComponent.vue";
import BreakflowComponent from "@/components/common/BreakflowComponent/BreakflowComponent.vue";
import { LocalHostClient } from "@/api/localHostClient";
import { SignPuddle3Client } from "@/api/SignPuddle3Client";
import { useI18n } from "vue-i18n";
import FeatureToggle from "@/utils/FeatureToggle";
import appStore from "@/stores/AppStore";

const { t } = useI18n();

const props = defineProps<{
  model: Page;
  pagesGlobalConfiguration: {
    showNonPrintableCharacters: boolean;
    pageOnFocusId: number;
    keepCaret: boolean; // It's a hack. Caret visibility should be refactored.
  };
}>();

const pageHasFocus = computed(
  () => props.pagesGlobalConfiguration.pageOnFocusId === props.model.id,
);
const emit = defineEmits([
  "focus",
  "focusOut",
  "update:text-changed",
  "update:keydown",
  "deletePage",
  "nextPage",
  "previousPage",
]);

const textarea = ref<HTMLTextAreaElement>();

function toggleTextAreaOnFocus() {
  if (pageHasFocus.value) {
    textarea.value?.focus();
  } else {
    textarea.value?.blur();
  }
}

watch(pageHasFocus, () => {
  toggleTextAreaOnFocus();
});

function newChange() {
  emit("update:text-changed", true);
}

/**
 * Adds a page item to the props.model. If the caret is at the end of the page, it adds the item at the end.
 * If the caret is at the beginning of the page, it adds the item at the beginning. If the caret is in the middle,
 * it adds the item after the item in focus.
 */
async function addPageItem(callback: Function, info?: Partial<addItem>) {
  const defaultInfo: addItem = {
    referenceItemId: info?.referenceItemId ?? undefined,
    addBefore: info?.addBefore ?? false,
    text: info?.text ?? "",
    signFromApi: info?.signFromApi ?? undefined,
  };

  updateCaret();

  await nextTick();

  // 1. if caret is the only one of the list, it should just add the element;
  if (!caretNextSibling.value && !caretPreviousSibling.value) {
    callback.bind(props.model)(defaultInfo);

    newChange();
    updateCaret();

    await nextTick(() => {
      moveCareToBeforeNextSibling();
    });

    console.debug(
      "PageComponent:addPageItem: Caret is the only one of the list",
    );

    return;
  }

  // 2. if caret is the first one, but has a nextsibling, it should add the item before nextsibling;
  if (!caretPreviousSibling.value && caretNextSibling.value) {
    callback.bind(props.model)({
      ...defaultInfo,
      referenceItemId: caretNextSibling.value?.id,
      addBefore: true,
      text: info?.text,
    });

    newChange();
    updateCaret();

    await nextTick(() => {
      moveCareToBeforeNextSibling();
    });

    console.debug(
      "PageComponent:addPageItem: Caret is the first one, but has a nextsibling",
    );

    return;
  }

  // 3. If caret is the the last one of the list, but has a previous sibling, add the element after the previous sibling;
  if (!caretNextSibling.value && caretPreviousSibling.value) {
    callback.bind(props.model)({
      ...defaultInfo,
      referenceItemId: caretPreviousSibling.value?.id,
    });

    newChange();
    updateCaret();

    await nextTick(() => {
      moveCareToBeforeNextSibling();
    });

    console.debug(
      "PageComponent:addPageItem: Caret is the last one of the list, but has a previous sibling",
    );

    return;
  }

  // 4. If caret is not the first nor the last, it should add the element after caret's previous sibling.
  if (caretNextSibling.value && caretPreviousSibling.value) {
    callback.bind(props.model)({
      ...defaultInfo,
      referenceItemId: caretPreviousSibling.value?.id,
    });

    newChange();
    updateCaret();

    await nextTick(() => {
      moveCareToBeforeNextSibling();
    });

    console.debug(
      "PageComponent:addPageItem: Caret is not the first nor the last",
    );

    return;
  }
}

const caretPreviousSibling = ref<Element | null>(null);
function setcaretPreviousSibling(item: Element) {
  caretPreviousSibling.value = item;
}

pageComponentAddSign({
  addSignToPage: (eventData: {
    pageId: number;
    sign: SignPuddleSearchEndPointResult;
  }) => {
    if (eventData.pageId == props.model.id) {
      addPageItem(props.model.addSign, {
        signFromApi: eventData.sign,
      });
    }
  },
});

pageComponentEditSignFsw({
  editFswOfPageSign: (eventData: {
    pageId: number;
    signId: string;
    fsw: string;
  }) => {
    if (eventData.pageId == props.model.id) {
      props.model.text.forEach((item) => {
        if (item.id === eventData.signId) {
          (item.details as SignDetails).fsw = eventData.fsw;
        }
      });

      newChange();
    }
  },
});

const caretNextSibling = ref<Element | null>(null);
function setcaretNextSibling(item: Element) {
  caretNextSibling.value = item;
}

async function deletePageItem() {
  // 0. if page is first one and it's empty, it should delete the page;
  if (props.model.text.length === 0 || !caretPreviousSibling.value?.id) {
    if (props.model.order > 1) {
      emit("deletePage");
    }
    return;
  }

  updateCaret();
  await nextTick();

  // 1. if caret is the laste one in the list, it should delete the previous sibling and not move;
  if (!caretNextSibling.value) {
    props.model.deletePageItem(caretPreviousSibling.value?.id);

    newChange();
    updateCaret();

    console.debug(
      "PageComponent:deletePageItem: Caret is the laste one in the list",
    );

    return;
  }

  // 2. If caret is not the first nor the last, it should delete the previous sibling and move the caret to the new previous sibling.
  if (caretNextSibling.value && caretPreviousSibling.value) {
    props.model.deletePageItem(caretPreviousSibling.value?.id);

    newChange();
    updateCaret();

    await nextTick(() => {
      moveCareToBeforePrevSibling();
    });

    console.debug(
      "PageComponent:deletePageItem: Caret is not the first nor the last",
    );

    return;
  }
}

const careToBeforePrevSibling = ref(false);
function moveCareToBeforePrevSibling() {
  careToBeforePrevSibling.value = !careToBeforePrevSibling.value;
}
const careToBeforeNextSibling = ref(false);
function moveCareToBeforeNextSibling() {
  careToBeforeNextSibling.value = !careToBeforeNextSibling.value;
}
const shouldUpdateCaret = ref(false);
function updateCaret() {
  shouldUpdateCaret.value = !shouldUpdateCaret.value;
}

function handleArrowKeys(event: KeyboardEvent) {
  const { writing } = props.model;
  const isVerticalMode = writing?.mode === "vertical";
  const isHorizontalMode = writing?.mode === "horizontal";

  if (
    (isVerticalMode && event.key === "ArrowUp") ||
    (isHorizontalMode && event.key === "ArrowLeft")
  ) {
    moveCareToBeforePrevSibling();

    if (props.model.order > 1 && pageHasFocus && !caretPreviousSibling.value) {
      emit("previousPage");
    }
    return;
  }

  if (
    (isVerticalMode && event.key === "ArrowDown") ||
    (isHorizontalMode && event.key === "ArrowRight")
  ) {
    moveCareToBeforeNextSibling();

    if (pageHasFocus.value && !caretNextSibling.value) {
      emit("nextPage");
    }
    return;
  }
}

function handleKeyDown(event: Event) {
  const eventAsKeyboardEvent = event as KeyboardEvent;
  emit("update:keydown", eventAsKeyboardEvent);

  if (
    (eventAsKeyboardEvent.key === "ArrowUp" ||
      eventAsKeyboardEvent.key === "ArrowDown" ||
      eventAsKeyboardEvent.key === "ArrowLeft" ||
      eventAsKeyboardEvent.key === "ArrowRight" ||
      eventAsKeyboardEvent.key === "Space" ||
      eventAsKeyboardEvent.key === "Enter" ||
      eventAsKeyboardEvent.key === "Tab" ||
      eventAsKeyboardEvent.key === "Escape" ||
      eventAsKeyboardEvent.key === " ") &&
    pageHasFocus.value
  ) {
    event.preventDefault();
  }

  handleArrowKeys(eventAsKeyboardEvent);

  if (eventAsKeyboardEvent && /[0-9]/.test(eventAsKeyboardEvent.key)) {
    addPageItem(props.model.addNumber, { text: eventAsKeyboardEvent.key });
    return;
  }

  switch (eventAsKeyboardEvent.key) {
    case "Backspace":
      deletePageItem();
      break;
    case "Tab":
      addPageItem(props.model.addLongSpace);
      break;
    case "Enter":
      if (FeatureToggle.isBreakflowEnabled()) {
        addPageItem(props.model.addBreakflow);
      } else {
        addNotificationToAppAlertEvent.emit("addNotificationToAppAlertEvent", {
          id: "breakflow-not-implemented",
          type: undefined,
          title: t("alerts.pageComponent.breakflowNotImplemented.title"),
          text: t("alerts.pageComponent.breakflowNotImplemented.text"),
        });
      }
      break;
    case " ":
      addPageItem(props.model.addSpace);
      break;
    case ",":
      addPageItem(props.model.addComma);
      break;
    case ".":
      addPageItem(props.model.addPeriod);
      break;
    case ":":
      addPageItem(props.model.addColon);
      break;
    case "!":
      addPageItem(props.model.addExclamationMark);
      break;
    case "?":
      addPageItem(props.model.addQuestionMark);
      break;
    case "(":
      addPageItem(props.model.addOpenParenthesis);
      break;
    case ")":
      addPageItem(props.model.addCloseParenthesis);
      break;
    default:
      return;
  }
}

const dynamicComponent = shallowRef();

/**
 * Render a component with the given props.
 *
 * @template T The component type
 *
 * @param content The component to render
 * @param props The props to pass to the component
 *
 * @returns A Vue component definition that renders the given component with the given props
 */
function render<T extends Component>(
  content: T,
  props: Partial<ComponentProps<T>> = {},
): ReturnType<typeof defineComponent> {
  return defineComponent({
    render() {
      return h(content, props);
    },
  });
}

function defineDynamicComponent(itemType: PageItemType) {
  switch (itemType.type) {
    case "punctuation":
      return (dynamicComponent.value = markRaw(
        render(PunctuationComponent, {
          type: itemType.details as PunctuationDetails,
          textDirection: props.model.configuration.writing?.mode,
          showNonPrintableCharacters:
            props.pagesGlobalConfiguration.showNonPrintableCharacters,
        }),
      ));
    case "number":
      return (dynamicComponent.value = markRaw(
        render(NumberComponent, {
          number: itemType.details as NumberDetails,
          textDirection: props.model.configuration.writing?.mode,
        }),
      ));
    default:
      return "div";
  }
}

/**
 * This function is used to get the most close parent element to the page content.
 * It is used to get the last element of the page-content when the user clicks on the page.
 * @param element
 * @returns
 */
function getParentElementMostCloseToPageContent(
  element: HTMLElement,
): HTMLElement {
  if (element.parentElement?.classList.contains("page-content")) {
    return element;
  }
  return getParentElementMostCloseToPageContent(
    element.parentElement as HTMLElement,
  );
}

/**
 * This function is used to get the target element when the user clicks on the page.
 * It is used to get the last element of the page-content when the user clicks on the page.
 * @param target
 * @returns
 */
function getTargetElement(target: HTMLElement): HTMLElement {
  if (target.classList.contains("page-content")) {
    return target.childNodes[target.childNodes.length - 1] as HTMLElement;
  } else {
    return getParentElementMostCloseToPageContent(target);
  }
}

const moveCaretToBefore = ref<HTMLElement | null>(null);

function handlePageClick(event: Event) {
  emit("focus");
  moveCaretToBefore.value = getTargetElement(event.target as HTMLElement);
}

function handleSignDoubleClick(item: PageItemType) {
  signMakerSignToEditEvent.emit("signMakerSignToEdit", {
    fsw: (item.details as SignDetails).fsw,
    signId: item.id,
    pageId: props.model.id,
  });
}

onMounted(() => {
  toggleTextAreaOnFocus();
});
</script>
<template>
  <div class="page" ref="pages" :theme="appStore.state.theme.selected.value">
    <div
      class="page-container"
      :style="`min-width: ${props.model.width}px; max-width: ${props.model.width}px; height: ${props.model.height}px; max-height: ${props.model.height}px; padding: ${props.model.padding?.top}px ${props.model.padding?.right}px ${props.model.padding?.bottom}px ${props.model.padding?.left}px;`"
      @click="handlePageClick"
      @touchstart="handlePageClick"
    >
      <div
        class="page-content"
        :text-direction="props.model.configuration.writing?.mode"
      >
        <SignColumn
          v-for="(word, index) in props.model.text"
          :key="index"
          :id="word.id"
          :item-id="word.id"
          :page-item-type="word.type"
          :item="word"
          :text-direction="props.model.configuration.writing?.mode"
        >
          <component
            v-if="!['sign', 'signPunctuation', 'breakflow'].includes(word.type)"
            :is="defineDynamicComponent(word)"
          />
          <BreakflowComponent
            v-else-if="word.type === 'breakflow'"
            :pageConfigurations="{
              height: props.model.height,
              width: props.model.width,
              padding: props.model.padding!,
              orientation: props.model.configuration.orientation!,
            }"
            :id="word.id"
            :showNonPrintableCharacters="
              props.pagesGlobalConfiguration.showNonPrintableCharacters
            "
          />
          <SignComponent
            v-else-if="word.type === 'sign'"
            :sign="word.details as SignDetails"
            :local-host-client="new LocalHostClient()"
            :sign-puddle-client="new SignPuddle3Client()"
            @dblclick="handleSignDoubleClick(word)"
          />
          <SignPunctuation
            v-else-if="word.type === 'signPunctuation'"
            :sign="word.details as SignPunctuationDetails"
            :text-direction="model.writing?.mode"
          />
        </SignColumn>
        <Caret
          :page="props.model"
          :move-caret-before-item="moveCaretToBefore"
          :move-up="careToBeforePrevSibling"
          :move-down="careToBeforeNextSibling"
          :updateSiblings="shouldUpdateCaret"
          :be-visible="pageHasFocus || props.pagesGlobalConfiguration.keepCaret"
          @update:previous-sibling="setcaretPreviousSibling"
          @update:next-sibling="setcaretNextSibling"
        />
      </div>
    </div>
    <textarea
      class="hidden-textarea"
      @keydown="handleKeyDown($event)"
      @focus="emit('focus')"
      @focusout="emit('focusOut')"
      aria-hidden="true"
      aria-disabled="true"
      ref="textarea"
    >
        <!-- Hidden. It's here just to get focus, toggle mobile virtual keyboard, and have its Events redirected to pageprops.model. -->
  </textarea
    >
  </div>
</template>
<style scoped lang="scss">
.page {
  width: 100%;
  display: block;
  overflow: auto;

  .page-container {
    margin: auto;
    padding: 1rem 1rem;
    background-color: white;
    border: 1px solid rgb(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  textarea {
    position: fixed;
    width: 0%;
  }

  .page-content {
    position: relative; // This is needed to make this element the offset parent of its children. BreakflowComponent uses this to calculate its height.
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    height: 100%;
    width: 100%;

    &:hover {
      cursor: text;
    }

    &[text-direction="vertical"] {
      flex-direction: column;
    }

    &[text-direction="horizontal"] {
      flex-direction: row;
      row-gap: 0.5rem;
    }
  }

  &[theme="dark"] {
    background-color: transparent;
    .page-container {
      background-color: rgb(96, 89, 89);
    }
  }
}

@media screen and (max-width: 600px) {
  .page {
    display: block;
    padding: 0;
  }
}

@media print {
  .page {
    .page-container {
      border: none;
      padding: 0;
    }
  }
}
</style>
