<script setup lang="ts">
import BaseNavigationBar from "@/components/common/BaseNavigationBar/BaseNavigationBar.vue";
import PageComponent from "@/components/common/Page/PageComponent.vue";
import { DocumentEditorModel } from "./models/DocumentEditorModel";
import {
  defineComponent,
  ref,
  type Component,
  markRaw,
  reactive,
  h,
} from "vue";
import type { ComponentProps } from "vue-component-type-helpers";
import TextInputDialog from "./TextInputDialog/TextInputDialog.vue";
import { shallowRef } from "vue";
import { TextInputDialogModel } from "./TextInputDialog/model/TextInputDialogModel";
import { computed } from "vue";
import { LocalHostClient } from "@/api/localHostClient";
import { useI18n } from "vue-i18n";
import OverlayDialog from "./OverlayDialog/OverlayDialog.vue";
import FswInputDialog from "./FswInputDialog/FswInputDialog.vue";
import type { Page } from "@/components/common/Page/Page";
import { nextTick } from "vue";
import SignMaker from "./SignMaker/SignMaker.vue";
import documentEditorStore from "@/stores/DocumentEditorStore";
import appStore from "@/stores/AppStore";

const { t } = useI18n();

const { model } = defineProps<{ model: DocumentEditorModel }>();

const pages = model.getPages();

const pageOnFocus = computed<Page>(
  () => pages.filter((page) => page.id === lastPageOnFocus.value)[0],
);
const hasChanged = ref(false);

let timeout: any = null;

/**
 * Debounce function, to avoid making too many requests. It will wait for the
 * user to stop typing for a certain amount of time before making the request.
 *
 * @param func Function to be debounced
 * @param delay Delay in milliseconds
 */
function debounce(func: Function, delay: number) {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
}

function savePage() {
  model.savePage();
  hasChanged.value = false;
}

function debounceAutomaticSaving() {
  debounce(() => {
    savePage();
  }, 600);
}

function triggerPageAutomaticSave() {
  hasChanged.value = true;

  debounceAutomaticSaving();
}

function saveDocumentMetadata() {
  if (documentTitle.original === documentTitle.edited) {
    return;
  }

  if (documentTitle.edited === "") {
    documentTitle.edited = t("document.defaultTitle");
  }

  model.saveDocumentTitle(documentTitle.edited);
}

const documentTitle = reactive({
  original:
    model.getDocumentMetadata().title.length > 0
      ? model.getDocumentMetadata().title
      : t("document.defaultTitle"),
  edited:
    model.getDocumentMetadata().title.length > 0
      ? model.getDocumentMetadata().title
      : t("document.defaultTitle"),
});

const renameFocused = ref(false);

function renameFocusOut() {
  renameFocused.value = false;
  saveDocumentMetadata();
}

const showDialog = ref(false);
const dialogComponent = shallowRef<Component>({});

/**
 * Render a component with the given props.
 *
 * @template T The component type
 *
 * @param content The component to render
 * @param props The props to pass to the component
 *
 * @returns A Vue component definition that renders the given component with the given props
 */
function render<T extends Component>(
  content: T,
  props: Partial<ComponentProps<T>> = {},
): ReturnType<typeof defineComponent> {
  return defineComponent({
    render() {
      return h(content, props);
    },
  });
}

function nextPage(currentOrder: number) {
  if (currentOrder === pages.length) {
    return;
  }
  const nextPage = pages.filter((page) => page.order === currentOrder + 1);

  if (nextPage.length === 0) {
    console.error("DocumentEditor: nextPage: No next page found: ", nextPage);
  }

  pageGotFocus(nextPage[0].id);
}

function previousPage(currentOrder: number) {
  if (currentOrder === 1) {
    return;
  }
  const prevPage = pages.filter((page) => page.order === currentOrder - 1);

  if (prevPage.length === 0) {
    console.error(
      "DocumentEditor: previousPage: No previous page found: ",
      prevPage,
    );
  }

  pageGotFocus(prevPage[0].id);
}

const lastPageOnFocus = ref<number>(0);
/**
 * Get the focus of the page, update the model and use the page id to set the lastPageOnFocus.
 * If page lose focus and no other page is focused, the id will not change. If another page is focused, the id will be updated.
 *
 * @param id The id of the page
 */
function pageGotFocus(id: number) {
  documentEditorStore.setPageOnFocusId(id);

  // Save the id of last page on focus, and only update it if another page is focused
  if (lastPageOnFocus.value !== id) {
    lastPageOnFocus.value = id;
  }
}

function noPageOnFocus() {
  documentEditorStore.setPageOnFocusId(0);
}

function openSignPuddleSearchDialog() {
  dialogComponent.value = render(TextInputDialog, {
    model: new TextInputDialogModel(),
    pageOnFocusId: lastPageOnFocus.value,
    localHostClient: new LocalHostClient(),
  });
  showDialog.value = true;
}

function openInsertFswDialog() {
  dialogComponent.value = render(FswInputDialog, {
    pageOnFocusId: lastPageOnFocus.value,
    localHostClient: new LocalHostClient(),
  });
  showDialog.value = true;
}

function onPageKeyDown(event: KeyboardEvent) {
  // Ctrl + Cmd/Alt + P to open the dialog
  if (event.ctrlKey && (event.metaKey || event.altKey) && event.key === "p") {
    event.preventDefault();
    openSignPuddleSearchDialog();
    return;
  }

  // Ctrl + Cmd/Alt + S to save the page
  if (
    (event.metaKey || event.altKey) &&
    event.key === "s" &&
    hasChanged.value
  ) {
    event.preventDefault();
    savePage();
    return;
  }

  // Ctrl/Cmd + Enter to add a new page
  if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
    event.preventDefault();
    addNewPage();
    return;
  }

  // // Enter
  // if (event.key === "Enter" && false) {
  //   event.preventDefault();
  //   addNotificationToAppAlertEvent.emit("addNotificationToAppAlertEvent", {
  //     id: "breakflow-not-implemented",
  //     type: undefined,
  //     title: t("alerts.pageComponent.breakflowNotImplemented.title"),
  //     text: t("alerts.pageComponent.breakflowNotImplemented.text"),
  //   });
  //   return;
  // }
}

function onDialogClick(click: MouseEvent | TouchEvent) {
  // Close the dialog if the user clicks outside of its content.
  if ((click.target as HTMLElement).classList.contains("dialog")) {
    showDialog.value = false;
    pageGotFocus(lastPageOnFocus.value);
  }
}

function onDialogComponentFocusOut() {
  showDialog.value = false;
  pageGotFocus(lastPageOnFocus.value);
}

function setPageWritingMode(newMode: "vertical" | "horizontal") {
  if (pageOnFocus.value.writing?.mode) {
    pageOnFocus.value.writing.mode = newMode;
  }
  triggerPageAutomaticSave();
}

function addNewPage() {
  model.addPage(lastPageOnFocus.value + 1);
  triggerPageAutomaticSave();

  nextTick(() => {
    pageGotFocus(lastPageOnFocus.value + 1);
  });
}

const userPlatform = computed(() => {
  const source = window.navigator.userAgent;
  if (source.includes("Mac")) {
    return "Mac";
  }

  if (source.includes("Windows")) {
    return "Windows";
  }

  return "Windows";
});

const userPlatformShortcuts = reactive({
  signPuddleSearch:
    userPlatform.value === "Mac" ? "control + ⌘ + p" : "ctrl + alt + p",
  savePage: userPlatform.value === "Mac" ? "control + ⌘ + s" : "ctrl + alt + s",
  addPage: userPlatform.value === "Mac" ? "⌘ + return" : "ctrl + enter",
});

function deletePage(page: Page) {
  pages.splice(pages.indexOf(page), 1);
  triggerPageAutomaticSave();
  pageGotFocus(pages[pages.length - 1].id);
}
</script>
<template>
  <div
    class="text-editor-container"
    :theme="appStore.state.theme.selected.value"
  >
    <BaseNavigationBar>
      <div class="tools">
        <div class="document-metadata">
          <div :class="['rename', { renameFocused }]">
            <input
              v-model="documentTitle.edited"
              @change="triggerPageAutomaticSave"
              @keydown.escape="documentTitle.edited = documentTitle.original"
              @focus="renameFocused = true"
              @focusout="renameFocusOut"
              @keydown.enter="saveDocumentMetadata"
              style="width: 100%"
            />
          </div>
          <p class="automatic-save">
            {{
              hasChanged
                ? t("textEditor.topBar.document.automaticSave.saving")
                : t("textEditor.topBar.document.automaticSave.saved")
            }}
          </p>
        </div>
        <div class="page-and-text">
          <div class="tools__page">
            <v-menu location="bottom" class="menu">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" size="small" variant="plain">
                  <p>{{ t("textEditor.topBar.format.title") }}</p>
                </v-btn>
              </template>

              <v-list>
                <!-- PAGE -->
                <v-list-subheader>
                  <p>
                    {{ t("textEditor.topBar.format.page.title") }}
                  </p>
                </v-list-subheader>
                <v-list-item
                  slim
                  ripple
                  @click="pageOnFocus.setOrientation('portrait')"
                >
                  <button>
                    <p style="font-size: 0.95rem">
                      {{
                        t("textEditor.topBar.format.page.orientation.vertical")
                      }}
                    </p>
                  </button>
                </v-list-item>
                <v-list-item
                  slim
                  @click="pageOnFocus.setOrientation('landscape')"
                >
                  <button>
                    <p style="font-size: 0.95rem">
                      {{
                        t(
                          "textEditor.topBar.format.page.orientation.horizontal",
                        )
                      }}
                    </p>
                  </button>
                </v-list-item>
                <!-- TEXT -->
                <v-list-subheader>
                  <p>
                    {{ t("textEditor.topBar.format.text.title") }}
                  </p>
                </v-list-subheader>
                <v-list-item
                  slim
                  ripple
                  @click="setPageWritingMode('vertical')"
                >
                  <button>
                    <p style="font-size: 0.95rem">
                      {{
                        t("textEditor.topBar.format.text.direction.topToBottom")
                      }}
                    </p>
                  </button>
                </v-list-item>
                <v-list-item slim @click="setPageWritingMode('horizontal')">
                  <button>
                    <p style="font-size: 0.95rem">
                      {{
                        t("textEditor.topBar.format.text.direction.leftToRight")
                      }}
                    </p>
                  </button>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" size="small" variant="plain">
                  <p>{{ t("textEditor.topBar.insert.title") }}</p>
                </v-btn>
              </template>

              <v-list>
                <v-list-item slim ripple @click="openSignPuddleSearchDialog">
                  <div class="menu__list-item">
                    <button>
                      <p style="font-size: 0.95rem">
                        {{ t("textEditor.topBar.insert.sign") }}
                      </p>
                    </button>
                    <div class="keyboard-shortcut">
                      {{ userPlatformShortcuts.signPuddleSearch }}
                    </div>
                  </div>
                </v-list-item>
                <v-list-item slim @click="openInsertFswDialog">
                  <button>
                    <p style="font-size: 0.95rem">
                      {{ t("textEditor.topBar.insert.fsw") }}
                    </p>
                  </button>
                </v-list-item>
                <v-list-item slim @click="addNewPage">
                  <div class="menu__list-item">
                    <button>
                      <p style="font-size: 0.95rem">
                        {{ t("textEditor.topBar.insert.page") }}
                      </p>
                    </button>
                    <div class="keyboard-shortcut">
                      {{ userPlatformShortcuts.addPage }}
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" size="small" variant="plain">
                  <p>{{ t("textEditor.topBar.view.title") }}</p>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  slim
                  ripple
                  @click="
                    documentEditorStore.setShowNonPrintableCharacters(
                      !documentEditorStore.state.showNonPrintableCharacters,
                    )
                  "
                >
                  <button>
                    <v-checkbox
                      hide-details
                      density="compact"
                      hide-spin-buttons
                      :value="
                        documentEditorStore.state.showNonPrintableCharacters
                      "
                    >
                      <template #label>
                        <span style="font-size: 0.95rem">
                          {{
                            t(
                              "textEditor.topBar.view.showNonPrintableCharacters",
                            )
                          }}
                        </span>
                      </template>
                    </v-checkbox>
                  </button>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </BaseNavigationBar>
    <div class="sheets-container">
      <PageComponent
        v-for="(page, index) in pages.sort((a, b) => a.order - b.order)"
        :key="index"
        :model="page"
        :pages-global-configuration="{
          showNonPrintableCharacters:
            documentEditorStore.state.showNonPrintableCharacters,
          pageOnFocusId: documentEditorStore.state.pageOnFocusId,
          keepCaret: showDialog && lastPageOnFocus === page.id,
        }"
        @focus="pageGotFocus(page.id)"
        @focusOut="noPageOnFocus"
        @update:text-changed="triggerPageAutomaticSave"
        @update:keydown="onPageKeyDown"
        @delete-page="deletePage(page)"
        @previous-page="previousPage(page.order)"
        @next-page="nextPage(page.order)"
      />
    </div>
    <OverlayDialog
      :show="showDialog"
      @click="onDialogClick"
      @touchstart="onDialogClick"
      class="no-printable"
    >
      <component
        :is="markRaw(dialogComponent)"
        @focusOut="onDialogComponentFocusOut"
        v-if="showDialog"
      />
    </OverlayDialog>
    <SignMaker />
  </div>
</template>
<style scoped lang="scss">
.text-editor-container {
  position: relative;
  background-color: rgb(128, 128, 128, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .tools {
    .document-metadata {
      display: flex;
      align-items: center;

      .rename {
        height: max-content;
        width: 12.5rem;
        margin-left: 0.4rem;

        input {
          width: 100%;
          box-sizing: border-box;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1rem;
          color: rgba(20, 23, 26, 0.9);
          font-weight: 600;

          &:focus {
            width: max-content;
            border: 1.5px solid rgba(64, 67, 69, 0.85);
          }
        }

        &.renameFocused {
          width: 30rem;
        }
      }

      .automatic-save {
        font-size: 0.8rem;
        color: rgba(20, 23, 26, 0.6);
      }
    }
    .page-and-text {
      width: 15rem;
      overflow-x: auto;
      .tools__page {
        display: flex;
        align-items: center;
        gap: 0;
      }
    }
  }

  .sheets-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 4rem;
  }

  &[theme="dark"] {
    background-color: rgb(0, 0, 0, 0.06);

    .sheets-container {
      background-color: rgb(23, 23, 23, 0.9);
    }

    .tools {
      .document-metadata {
        .rename {
          input {
            color: rgb(255, 255, 255, 0.8);
          }
        }
        .automatic-save {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .page-and-text {
        .tools__page {
          button {
            color: #c0c4c4;
          }
        }
      }
    }
  }
}

.menu__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .keyboard-shortcut {
    margin-left: 1rem;
    font-size: 0.8rem;
    color: rgba(20, 23, 26, 0.6);
  }
}

@media screen and (max-width: 600px) {
  .text-editor-container {
    .tools {
      overflow: scroll;

      .document-metadata {
        display: flex;
        flex-direction: column;
        align-items: center;

        .rename {
          width: 17rem;
          margin-left: 0.2rem;
          &.renameFocused {
            width: 15rem;
          }
        }
        .automatic-save {
          padding-left: 0.5rem;
          width: 100%;
          font-size: x-small;
        }
      }
    }

    .sheets-container {
      padding: 0;
    }
  }

  .menu__list-item {
    .keyboard-shortcut {
      display: none;
    }
  }
}

@media print {
  .text-editor-container {
    .sheets-container {
      padding-top: 0;
      padding-bottom: 0;
      gap: 0;
    }
  }
}
</style>
./models/DocumentEditorModel
