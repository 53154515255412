<script setup lang="ts">
import SignWriting from "@/components/common/SignWriting/SignWriting.vue";
import type { SignDetails } from "../Page/Page";
import { computed, reactive, ref, watch } from "vue";
import type { LocalHostClient } from "@/api/localHostClient";
import {
  SignPuddle3Client,
  SignPuddleMatch,
  type SignPuddlePayload,
  type SignPuddleSearchEndPointResult,
} from "@/api/SignPuddle3Client";

type SignComponentProps = {
  sign: SignDetails;
  localHostClient: LocalHostClient;
  signPuddleClient: SignPuddle3Client;
};

const props = defineProps<SignComponentProps>();

const reRenderingNecessary = computed(() => {
  const details = props.sign;

  if (undefined === details) {
    return;
  }

  return details.style?.fontSize;
});

const apiResults = reactive<SignPuddlePayload<SignPuddleSearchEndPointResult>>({
  meta: {
    limit: 0,
    location: "",
    offset: 0,
    searchTime: "",
    totalResults: 0,
  },
  results: [],
});

const currentSignIndex = ref(0);
const signToShow = ref<string>("");
const searchTerm = ref<string>("");

// const hasPreviousSign = computed(() => currentSignIndex.value > 0);
// const hasNextSign = computed(
//   () => currentSignIndex.value < apiResults.results.length - 1,
// );
const lastResultIsfromLocalStorage = ref(false);

const isLoading = ref(false);
async function loadingWrapper(
  doSomething: () => Promise<unknown>,
  ifError: (error: unknown) => void,
) {
  try {
    isLoading.value = true;
    await doSomething();
  } catch (error) {
    ifError(error);
  } finally {
    isLoading.value = false;
  }
}

function clearSearch() {
  apiResults.results = [];
  signToShow.value = "";
  currentSignIndex.value = 0;
  lastResultIsfromLocalStorage.value = false;
}

async function search(term: string | null) {
  clearSearch();
  if (!term || term.length == 0) {
    console.debug("TextInputDialog: search: No search term provided");
    return;
  }

  // Check Local Storage First
  const resultFromLocalStorage =
    props.localHostClient.getSignPuddleSearchEndPointResultItem(term);

  // If found in Local Storage, use it
  if (resultFromLocalStorage) {
    console.debug(
      "TextInputDialog: search: resultFromLocalStorage",
      resultFromLocalStorage,
    );

    lastResultIsfromLocalStorage.value = true;

    const oneResultApiResults = {
      meta: {
        limit: 0,
        location: "",
        offset: 0,
        searchTime: "",
        totalResults: 1,
      },
      results: [resultFromLocalStorage],
    };

    Object.assign(apiResults, oneResultApiResults);

    signToShow.value = resultFromLocalStorage.sign;
  } else {
    // If not found in Local Storage, call API
    callApiForSign(term);
  }
}

function callApiForSign(
  term: string,
  resultFromLocalStorage?: SignPuddleSearchEndPointResult,
) {
  loadingWrapper(
    async () => {
      const results = await props.signPuddleClient.getSignsByTerm(term, {
        match: SignPuddleMatch.Exact,
        puddleCode: "",
        countryCode: "string",
      });

      if (results.meta.totalResults === 0 && !resultFromLocalStorage) {
        console.debug("TextInputDialog: callApiForSign: No results found");
        clearSearch();
        return;
      }

      // Filter out results that don't have a sign from API results
      results.results = results.results.filter(
        (result) => result.sign.length > 0,
      );

      // Remove the result from local storage if it is in the API results
      if (resultFromLocalStorage) {
        results.results.filter(
          (result) => result.id !== resultFromLocalStorage.id,
        );

        // Add the result from local storage to the beginning of the API results
        results.results.unshift(resultFromLocalStorage);
      }

      Object.assign(apiResults, results);

      if (apiResults.results[currentSignIndex.value].sign.length > 0) {
        lastResultIsfromLocalStorage.value = false;
        signToShow.value = apiResults.results[currentSignIndex.value].sign;
      }
    },
    (error) => {
      throw Error("TextInputDialog:search: An error occurred: " + error);
    },
  );
}

let timeout: any = null;

/**
 * Debounce function, to avoid making too many requests. It will wait for the
 * user to stop typing for a certain amount of time before making the request.
 *
 * @param func Function to be debounced
 * @param delay Delay in milliseconds
 */
function debounce(func: Function, delay: number) {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
}

function debouncedSearch(term: string) {
  debounce(() => {
    search(term);
  }, 400);
}

const key = ref<number>(0); // This is a hack to force re-rendering of the component
watch(reRenderingNecessary, () => {
  if (reRenderingNecessary.value !== undefined) {
    key.value++;
  }
});
</script>
<template>
  <div class="sign-component">
    <!-- activator="parent" -->
    <v-overlay location-strategy="connected" :scrim="false" location="top">
      <div class="text-input">
        <v-text-field
          density="compact"
          label=""
          variant="solo"
          hide-details
          clearable
          autofocus
          v-model="searchTerm"
          @update:model-value="debouncedSearch($event)"
        >
          <template #append-inner>
            <v-icon size="small">filter</v-icon>
          </template>
        </v-text-field>
      </div>
    </v-overlay>
    <div>
      <SignWriting
        :fsw="signToShow.length > 0 ? signToShow : props.sign.fsw"
        :font-size="sign.style?.fontSize"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.sign-component {
  position: relative;
}

.text-input {
  width: 15rem;
  margin-bottom: 0.5rem;
}
</style>
