<script setup lang="ts">
import { computed } from "vue";
import type { PunctuationDetails } from "../Page/Page";

type PunctuationComponentProps = {
  type: PunctuationDetails;
  showNonPrintableCharacters?: boolean;
  textDirection: "vertical" | "horizontal" | undefined;
};

const props = defineProps<PunctuationComponentProps>();

const punctuationClass = computed(() => {
  if (props.type.type === "space") {
    return "space";
  }

  if (props.type.type === "long-space") {
    return "long-space";
  }

  const error = new Error(
    `PunctuationComponent: Unknown punctuation type "${props.type.type}".`,
  );
  throw error;
});

const show = computed(() => {
  if (props.showNonPrintableCharacters) {
    return "show";
  }
  return false;
});
</script>
<template>
  <div
    :class="`punctuation ${punctuationClass} ${show}`"
    :text-direction="textDirection"
  >
    <div class="punctuation__visual-cue"></div>
  </div>
</template>
<style scoped lang="scss">
.punctuation {
  position: relative;
  width: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.space {
    height: 1rem;
    &.show {
      .punctuation__visual-cue {
        width: 0.57rem;
        height: 0.59rem;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
      }

      &[text-direction="horizontal"] {
        height: 2.5rem;
      }
    }
  }

  &.long-space {
    height: 2rem;

    &.show {
      .punctuation__visual-cue::after {
        content: "\2193"; // Unicode for down arrow
        display: block;
        color: rgba(0, 0, 0, 0.5);
      }

      &[text-direction="horizontal"] {
        height: 2.5rem;
        .punctuation__visual-cue::after {
          content: "\2192"; // Unicode for right arrow
        }
      }
    }
  }

  &[text-direction="horizontal"] {
    &.space {
      width: 1rem;
    }

    &.long-space {
      width: 2rem;
    }
  }
}

@media print {
  .punctuation__visual-cue {
    display: none;
  }
}
</style>
@/components/common/Page/Page
