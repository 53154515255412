<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue";

type BreakFlowComponentProps = {
  pageConfigurations: {
    height: number;
    width: number;
    padding: { top: number; right: number; bottom: number; left: number };
    orientation: "portrait" | "landscape";
  };
  id: string;
  showNonPrintableCharacters: boolean;
};

const props = defineProps<BreakFlowComponentProps>();

const DEFAULT_BREAKFLOW_HEIGHT = 50;
const DEFAULT_BREAKFLOW_WIDTH = 50;
const height = ref<number>(DEFAULT_BREAKFLOW_HEIGHT);
const width = ref<number>(DEFAULT_BREAKFLOW_WIDTH);

const breakflow = ref<Element | null>();
/**
 * @description This function sets the breakflow element reference to the breakflow ref.
 * @param {Element | null} el - The breakflow element reference.
 */
function addRef(el: Element | null) {
  if (el) {
    breakflow.value = el;
  }
}

/**
 * @description This function gets the parent element of the breakflow element until it reaches the element whose parent has the specified class.
 * @param {Element | null} element - The breakflow element.
 * @param {string} className - The class of the parent element to stop at.
 * @returns {Element | null} The element whose parent has the specified class.
 */
function getElementWithParentClass(
  element: Element | null,
  className: string,
): Element | null {
  if (!element || !element.parentElement) {
    return null;
  }

  if (element.parentElement.classList.contains(className)) {
    return element;
  }

  return getElementWithParentClass(element.parentElement, className);
}

function calculateDimensions() {
  if (!breakflow.value) return;

  // Sign Column that wraps the breakflow component
  const earliestRelativeUnderPageContent = getElementWithParentClass(
    breakflow.value,
    "page-content",
  ) as HTMLElement;

  if (!earliestRelativeUnderPageContent) return;

  let distanceToTop = 0;
  let distanceToLeft = 0;

  if (earliestRelativeUnderPageContent.previousElementSibling) {
    const previousSibling =
      earliestRelativeUnderPageContent.previousElementSibling as HTMLElement;

    // OffsetParent is page-component, so OFFSETTOP is the distance between its children and the top of the page
    if (previousSibling) {
      distanceToTop =
        previousSibling.offsetTop > 0
          ? previousSibling.offsetTop + previousSibling.clientHeight
          : previousSibling.clientHeight;

      distanceToLeft =
        previousSibling.offsetLeft > 0
          ? previousSibling.offsetLeft + previousSibling.clientWidth
          : previousSibling.clientWidth;
    }
  }

  const pageAvailableHeight =
    props.pageConfigurations.height -
    (props.pageConfigurations.padding.top +
      props.pageConfigurations.padding.bottom);

  const pageAvailableWidth =
    props.pageConfigurations.width -
    (props.pageConfigurations.padding.left +
      props.pageConfigurations.padding.right);
  switch (props.pageConfigurations.orientation) {
    case "portrait":
      height.value = pageAvailableHeight - distanceToTop - 10;
      width.value = DEFAULT_BREAKFLOW_WIDTH;
      break;

    case "landscape":
      width.value = pageAvailableWidth - distanceToLeft - 10;
      height.value = DEFAULT_BREAKFLOW_HEIGHT;
      break;

    default:
      break;
  }
}

onMounted(() =>
  nextTick(() => {
    calculateDimensions();
  }),
);
</script>
<template>
  <div
    class="breakflow"
    :id="props.id"
    :style="{
      height: height + 'px',
      width: width + 'px',
    }"
    :ref="
      (el) => {
        addRef(el as Element);
        return undefined;
      }
    "
    :show="showNonPrintableCharacters"
  ></div>
</template>
<style scoped lang="scss">
.breakflow {
  &[show="true"] {
    border: 2px dashed rgb(163, 160, 160, 0.3);
  }
}
</style>
