import { get } from "./client";

export enum SignPuddleMatch {
  Start = "start",
  End = "end",
  Exact = "exact",
  Anywhere = "any",
}

export type SignPuddlePayload<T> = {
  meta: {
    limit: number;
    offset: number;
    totalResults: number;
    location: string;
    searchTime: string;
  };
  results: T[];
};

export type SignPuddleSearchEndPointResult = {
  id: string;
  user: string;
  created_at: string;
  updated_at: string;
  sign: string;
  signtext: string;
  terms: string[];
  text: string;
  source: string;
  detail: string[];
};

export type SignPuddleSignEndPointResult = {
  id: string;
  sign: string;
};

export type PuddleInfo = {
  code: string;
  qqq: string;
  name: string;
  icon: string;
  namespace?: string; // Optional to accommodate both uses
};

export type CountryPuddleResponse = SignPuddlePayload<{
  code: string;
  qqq: string;
  name: string;
  puddles: PuddleInfo[];
}>;

export type ApiConfigurations = {
  match?: SignPuddleMatch;
  puddleCode?: string;
  countryCode?: string;
};

export class SignPuddle3Client {
  private readonly _SIGN_PUDDLE_SERVER = "https://signpuddle.net";
  private readonly _CASE_INSENSITIVE = 1;
  private readonly _SORT_BY_DESCENDING_ORDER = "-";
  private readonly _SORT_BY_CREATED_AT = "created_at";
  private readonly _DEFAULT_FLAGS = `sort=${this._SORT_BY_DESCENDING_ORDER}${this._SORT_BY_CREATED_AT}&ci=${this._CASE_INSENSITIVE}`;

  getSignsByTerm(
    term: string,
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSearchEndPointResult> {
    const inputAsUrlParam = encodeURIComponent(term);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/search/${inputAsUrlParam}?${this._DEFAULT_FLAGS}&match=${configurations.match}`;
    const response:
      | SignPuddlePayload<SignPuddleSearchEndPointResult>
      | unknown = get(url);
    return response as SignPuddlePayload<SignPuddleSearchEndPointResult>;
  }

  getSignsByText(
    text: string,
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSignEndPointResult> {
    const inputAsUrlParam = encodeURIComponent(text);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/sign?text=${inputAsUrlParam}&${this._DEFAULT_FLAGS}&match=${configurations.match}`;
    const response: SignPuddlePayload<SignPuddleSignEndPointResult> | unknown =
      get(url);
    return response as SignPuddlePayload<SignPuddleSignEndPointResult>;
  }

  getSignsBySource(
    source: string,
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSignEndPointResult> {
    const inputAsUrlParam = encodeURIComponent(source);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/sign?source=${inputAsUrlParam}&${this._DEFAULT_FLAGS}&match=${configurations.match}`;
    const response: SignPuddlePayload<SignPuddleSignEndPointResult> | unknown =
      get(url);
    return response as SignPuddlePayload<SignPuddleSignEndPointResult>;
  }

  getSignsWithSymbols(
    symbols: string[],
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSignEndPointResult> {
    const symbolsAsUrlParam = encodeURIComponent(symbols.join(""));
    const inputAsUrlParam = encodeURIComponent(symbolsAsUrlParam);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/sign?query=${inputAsUrlParam}&${this._DEFAULT_FLAGS}`;
    const response: SignPuddlePayload<SignPuddleSignEndPointResult> | unknown =
      get(url);
    return response as SignPuddlePayload<SignPuddleSignEndPointResult>;
  }

  getSignsById(
    ids: string[],
    configurations: ApiConfigurations,
  ): SignPuddlePayload<SignPuddleSearchEndPointResult> {
    const symbolsAsUrlParam = encodeURIComponent(ids.join(","));
    const inputAsUrlParam = encodeURIComponent(symbolsAsUrlParam);

    const url = `${this._SIGN_PUDDLE_SERVER}/puddle/${configurations.puddleCode}/entry/${inputAsUrlParam}&${this._DEFAULT_FLAGS}`;
    const response:
      | SignPuddlePayload<SignPuddleSearchEndPointResult>
      | unknown = get(url);
    return response as SignPuddlePayload<SignPuddleSearchEndPointResult>;
  }

  getCountryPuddles(countryCode: string): CountryPuddleResponse {
    const url = `${this._SIGN_PUDDLE_SERVER}/world/country/${countryCode}`;
    const response: CountryPuddleResponse | unknown = get(url);
    return response as CountryPuddleResponse;
  }
}
