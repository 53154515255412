<script setup lang="ts">
import {
  computed,
  markRaw,
  ref,
  watch,
  type Component,
  type ComponentPublicInstance,
  type Raw,
  type Ref,
} from "vue";
import { render } from "@/utils/utils";
import type ColumnItem from "./model";
import { caretOnItemOfId } from "@/components/common/Page/Column/PageColumn.vue";
import { ColumnItemType } from "./model";
import SignWriting from "@/components/common/SignWriting/SignWriting.vue";
import SignPunctuation from "@/components/common/SignPunctuation/SignPunctuationOngoing.vue";
import Breakflow from "@/components/common/BreakflowComponent/BreakflowComponentOngoing.vue";
import Breakpage from "@/components/common/BreakpageComponent/BreakpageComponent.vue";
import Space from "@/components/common/SpaceComponent/SpaceComponent.vue";

const props = defineProps<{
  item: ColumnItem;
}>();

function instantiateComponent(
  itemType: ColumnItemType,
  parameters: { [key: string]: any },
): Raw<Component> {
  let component: Component = document.createElement("div");

  switch (itemType) {
    case ColumnItemType.SIGN:
      component = SignWriting;
      break;
    case ColumnItemType.SIGN_PUNCTUATION:
      component = SignPunctuation;
      break;
    case ColumnItemType.BREAK_FLOW:
      component = Breakflow;
      break;
    case ColumnItemType.BREAK_PAGE:
      component = Breakpage;
      break;
    case ColumnItemType.SPACE:
      component = Space;
      break;
  }

  return markRaw(render(component, parameters));
}

const hasCaret = computed(() => caretOnItemOfId.value === props.item.id);
const columnItemRef: Ref<Element | ComponentPublicInstance | null> = ref(null);

watch(
  () => hasCaret.value,
  (newValue) => {
    if (newValue && columnItemRef.value) {
      (columnItemRef.value as HTMLElement).scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
  },
);
</script>
<template>
  <div
    class="column-item"
    :style="{
      width: `100%`,
      height: `${item.height}px`,
    }"
    :ref="
      (el) => {
        columnItemRef = el;
      }
    "
  >
    <component :is="instantiateComponent(item.type, item.parameters)" />
    <span
      class="column-item__cover"
      data-status=""
      :data-caret="hasCaret"
      @click="caretOnItemOfId = item.id"
    ></span>
  </div>
</template>
<style scoped lang="scss">
.column-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .column-item__cover {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;

    &[data-status="hasCaret"] {
      background-color: rgba(0, 107, 247, 0.3);
    }

    &[data-caret="true"] {
      border-bottom: 1.5px solid black;
      animation: pulse 1s infinite;

      @keyframes pulse {
        0% {
          opacity: 1;
        }
        40% {
          opacity: 0;
        }
      }
    }
  }
}
</style>
