import { ref, type Ref } from "vue";
import { Page } from "../../../components/common/Page/Page";
import { LocalHostClient } from "@/api/localHostClient";

export class DocumentEditorModel {
  constructor(client: LocalHostClient, documentId: string) {
    if (null != client || undefined != client) {
      this._client = client;
    }
    const clientPages = client.getPagesByDocumentId(documentId);
    this._pages.value = clientPages;
    this._documentId = documentId;
  }

  private _pages: Ref<Page[]> = ref([]);
  private _client: LocalHostClient = new LocalHostClient();
  private _documentId: string = "";

  public getDocumentMetadata() {
    return this._client.getDocumentById(this._documentId);
  }

  public getTotalPages() {
    return this._pages.value.length;
  }

  public getPages(): Page[] {
    return this._pages.value as Page[];
  }

  public getPage(id: number) {
    return this._pages.value.filter((page) => page.id == id)[0];
  }

  public removePage(id: number) {
    const index = this._pages.value.findIndex((page) => page.id === id);
    if (index !== -1) {
      this._pages.value.splice(index, 1);
    }
  }

  public addPage(order: number) {
    const newPage = new Page({
      documentId: this._documentId,
      id: this._pages.value.length + 1,
      size: { width: 794, height: 1135 },
      order: order,
    });

    if (order <= this._pages.value.length) {
      this._pages.value.forEach((page) => {
        if (page.order >= order) {
          page.order += 1;
        }
      });
    }

    this._pages.value.push(newPage);
    this._pages.value.sort((a, b) => a.order - b.order);
  }

  public savePage() {
    this._client.setOrReplacePages(this._pages.value);
  }

  public saveDocumentTitle(metadata: string) {
    this._client.editDocumentMetadata(this._documentId, {
      title: metadata,
    });
  }
}
